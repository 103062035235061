import React from "react";
import { useTranslation } from "react-i18next";

import { useMe } from "../../../hooks/queries";
import { AccountType } from "../../../models/AccountData";
import { getSortCriteria } from "../ListTopBar";
import "./styles.scss";

export interface IFacilityListHeaderProps {
  filterFormData: any;
  onChangeFilterFormData: (filterFormData: any) => void;
}

export const FacilityListHeader: React.FunctionComponent<
  IFacilityListHeaderProps
> = ({ filterFormData, onChangeFilterFormData }) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`facilityListPage.facilityList.${key}`);
  const { data: me } = useMe();

  const { order, sort } = filterFormData;

  const isOrgUser = () => {
    return me?.type !== AccountType.LOC_ADMIN;
  };

  const performSort = (fieldName: string, filterString: string) => {
    let nextSort: string;
    if (fieldName === sort && order === "asc") {
      nextSort = `${filterString} ↓`;
    } else {
      nextSort = `${filterString} ↑`;
    }

    const criteria = getSortCriteria(nextSort);

    onChangeFilterFormData({
      ...filterFormData,
      sortBy: nextSort,
      ...criteria,
    });
  };

  const createButton = (fieldName: string, filterKey: string) => (
    <button onClick={() => performSort(fieldName, t(filterKey))}>
      {sort === fieldName
        ? `${t(filterKey)} ${order === "desc" ? "↓" : "↑"}`
        : t(filterKey)}
    </button>
  );

  return (
    <div className="row-th facility-list-header">
      <div className="col-th facility-name">
        <div className="spacing">{createButton("name", "facility_name")}</div>
      </div>
      <div className="col-th score center">
        <div className="spacing">
          {createButton("stats.score", "health_index")}
        </div>
      </div>
      <div className="col-th profile-views text-right">
        <div className="spacing">
          {createButton("stats.profile_views", "profile_views")}
          <div className="change">
            {createButton(
              "stats.profile_views_change_percentage",
              "profile_views_change_percentage"
            )}
          </div>
        </div>
      </div>
      <div className="col-th direction-search-count text-right">
        <div className="spacing">
          {createButton(
            "stats.direction_search_count",
            "direction_search_count"
          )}
          <div className="change">
            {createButton(
              "stats.direction_search_count_change_percentage",
              "direction_search_count_change_percentage"
            )}
          </div>
        </div>
      </div>
      <div className="col-th muni center">
        <div className="spacing">
          {createButton("prefecture+city", "municipality")}
        </div>
      </div>
      <div className="col-th category center">
        <div className="spacing">{createButton("category", "category")}</div>
      </div>
      {isOrgUser() && (
        <div className="col-th labels">
          <div className="spacing">{t("labels")}</div>
        </div>
      )}
    </div>
  );
};
