import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { ToolsMetrics } from "../../types";

import "./styles.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const lineGraphOptions = {
  options: {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
  },
};

const Keywords: React.FunctionComponent<{ metrics: ToolsMetrics }> = ({
  metrics,
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`internaltoolsPage.metricsNew.keywords.${key}`);

  const [wordsToFilter, setWordsToFilter] = useState<string[]>([]);
  const { searchKeywords } = metrics;

  const keywordList = Array.from(
    new Set(
      searchKeywords.flatMap((month) =>
        month.keywords.map((data) => data.searchKeyword)
      )
    )
  );
  const masterKeywords =
    wordsToFilter.length > 0
      ? keywordList.filter(
          (word) =>
            wordsToFilter.findIndex((wordToFilter) =>
              word.includes(wordToFilter)
            ) !== -1
        )
      : keywordList;

  const datasets = masterKeywords.map((keyword) => ({
    label: keyword,
    data: [] as (string | null)[],
    spanGaps: false,
    borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
  }));

  const labels: string[] = [];

  searchKeywords.forEach(({ year, month, keywords }) => {
    const date = `${year}/${month}`;
    labels.push(date);

    masterKeywords.forEach((keyword, index) => {
      const data = keywords.find(
        (element) => element.searchKeyword === keyword
      );
      datasets[index].data.push(data?.insightsValue.value ?? null);
    });
  });

  const onApplyFilters = () => {
    const value = (
      document.querySelector("#filteredKeywords") as HTMLInputElement
    ).value;
    if (!value) {
      setWordsToFilter([]);
      return;
    }

    setWordsToFilter(value.split(/、|,/));
  };

  return (
    <>
      <div className="line-container">
        <Line
          {...lineGraphOptions}
          data={{
            labels,
            datasets,
          }}
        />
      </div>
      <div>
        <input
          id="filteredKeywords"
          type="text"
          placeholder={t("filter_placeholder")}
        />
        <button className="btn btn-blue" onClick={onApplyFilters}>
          {t("apply_filters")}
        </button>
      </div>
    </>
  );
};

export default Keywords;
