/**
 * Should be kept in sync with location_review_summary table
 * Note: the `summary` field is a json populated by AI, so all inner fields are optional and must always be null-checked
 */
export type LocationReviewSummary = {
  id: bigint;
  location_id: bigint;
  summary: Summary;
  status: LocationReviewSummaryStatus;
  start_date: Date;
  end_date: Date;
  created_at: Date | null;
  created_by: bigint | null;
  last_modified_at: Date | null;
  last_modified_by: bigint | null;
};

/**
 * Populated by AI, so all data should be treated as suspect and null-checked
 */
type Summary = {
  summary?: string;
  improvements?: string[];
  negativeComments?: Comments;
  positiveComments?: Comments;
};

/**
 * Populated by AI, so all data should be treated as suspect and null-checked
 */
type Comments = {
  count?: number;
  points?: { count?: number; title?: string; example?: string }[];
};

/**
 * The data returned from the location review summary history endpoint for selecting a review summary
 */
export type LocationReviewSummaryHistory = {
  id: bigint;
  start_date: Date;
  end_date: Date;
};

export enum LocationReviewSummaryStatus {
  SUCCESS = "success",
  NOT_ENOUGH_REVIEWS = "not_enough_reviews",
  GEMINI_ERROR = "gemini_error",
  GEMINI_BAD_JSON = "gemini_bad_json",
}
