import React from "react";

import { useDateTranslation } from "../../../hooks/dateTranslation";
import { LocationReviewSummaryHistory } from "../../../models/LocationReviewSummary";
import DropdownSelect from "../../FormElement/DropdownSelect";

import "./styles.scss";

type IReviewSummarySelectorProps = {
  history: LocationReviewSummaryHistory[] | undefined;
  selectedId: bigint | undefined;
  onSelectId: (id: bigint | undefined) => void;
};

export const ReviewSummarySelector: React.FunctionComponent<
  IReviewSummarySelectorProps
> = ({ history, selectedId, onSelectId }) => {
  const { translateDateRange } = useDateTranslation();

  // Hack to use DropdownSelect, which only accepts an array of strings as items
  // So we map the timespan to an id, and return the id in the onChange handler
  const timespanIdMap: Record<string, bigint> = {};
  for (const h of history ?? []) {
    timespanIdMap[
      translateDateRange(
        // Cast to Date because the data is serialized as a string
        new Date(h.start_date),
        new Date(h.end_date)
      )
    ] = h.id;
  }

  const items = Object.keys(timespanIdMap);

  const onChange = (timespan: string) => {
    const id = timespanIdMap[timespan];
    // Return the id associated with the timespan
    onSelectId(id);
  };

  const selectedTimespan =
    items.find((item) => timespanIdMap[item] === selectedId) ?? "";

  return (
    <div className="review-summary-selector">
      <DropdownSelect
        value={selectedTimespan}
        items={items}
        onChange={onChange}
      />
    </div>
  );
};
