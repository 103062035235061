import { AccountData } from "../models/AccountData";

export const canAccessOrganization = (organizationId: string | number, me?: AccountData): boolean => {
  // Pass admins
  if(me?.type === "admin") {
    return true;
  }

  // User has no organization
  if (!me?.organization) {
    return false;
  }

  if (String(organizationId) === me?.organization?.id) {
    return true;
  }

  return false;
}
