import React from "react";
import { Label, Pie, PieChart } from "recharts";

import "./styles.scss";

interface IColoredPieChartProps {
  data: { name: string; value: number; fill: string }[];
  size?: number;
  label?: number | string;
}

/**
 * Generalized component for created a styled pie chart.
 */
export const ColoredPieChart: React.FunctionComponent<
  IColoredPieChartProps
> = ({ data, size = 200, label }) => {
  return (
    <div className="colored-pie-chart">
      <PieChart width={size * 1.6} height={size * 1.5}>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          innerRadius={size / 2.2}
          outerRadius={size / 2}
          paddingAngle={2}
          cornerRadius={10}
          label={({ percent }) => `${(percent * 100).toFixed(0)}%`}
          labelLine={false}
          animationDuration={0}
        >
          {label && (
            <Label
              value={label}
              position="center"
              fontSize={36}
              className="label"
            />
          )}
        </Pie>
        <Pie
          data={[{ value: 1, name: "inner-border" }]}
          dataKey="value"
          nameKey="name"
          fill="#D7DDE7"
          innerRadius={size / 2.4}
          outerRadius={size / 2.3}
          blendStroke
          animationDuration={0}
        />
      </PieChart>
    </div>
  );
};
