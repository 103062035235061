import React from "react";
import { useTranslation } from "react-i18next";

import {
  LocationReviewSummary,
  LocationReviewSummaryStatus,
} from "../../../../models/LocationReviewSummary";

interface IReviewSummarySummarySectionProps {
  summary: LocationReviewSummary | undefined;
}

export const ReviewSummarySummarySection = ({
  summary,
}: IReviewSummarySummarySectionProps) => {
  const { t: _t } = useTranslation();
  const t = (key: string, params = {}) =>
    _t(`facilityDetailsPage.reviewSummary.summarySection.${key}`, {
      ...params,
    });

  if (!summary || summary.status !== LocationReviewSummaryStatus.SUCCESS) {
    return (
      <div className="summary">{t(summary?.status ?? "unknown_status")}</div>
    );
  }
  return <div className="summary">{summary?.summary?.summary}</div>;
};
