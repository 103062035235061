import i18next from "i18next";
import React from "react";

import { percentage } from "./common";

export const formatNumWithNullNote = (v: any, isArchived?: boolean) => {
  if (v === null) {
    return (
      <small className="data-note">
        {i18next.t("data.data_not_available")}
      </small>
    );
  }

  return (
    <>
      {formatNum(v)}
      {isArchived && (
        <small className="data-note">{i18next.t("data.asterix")}</small>
      )}
    </>
  );
};

export const formatPercWithNullNote = (v: any, isArchived?: boolean) => {
  if (v === null) {
    return (
      <div className={`parent-num gray`}>
        <small className="data-note">
          {i18next.t("data.perc_change_not_calculable")}
        </small>
      </div>
    );
  }

  return (
    <div className={`parent-num ${v >= 0 ? "green" : "red"}`}>
      {v >= 0 ? "+" : ""}
      {percentage(v * 100)}%
      {isArchived && (
        <small className="data-note">{i18next.t("data.asterix")}</small>
      )}
    </div>
  );
};

// format Number
export const formatNum = (v = 0) => {
  const n = Number(v);
  if (!n) {
    return 0;
  }

  let counter = 0;

  const int = n.toString().split(".");
  const num = int[0].split("");
  const result = int[1] ? [`.${int[1]}`] : [];
  for (let i = num.length - 1; i >= 0; i--) {
    counter++;
    result.unshift(num[i]);
    if (!(counter % 3) && i !== 0) {
      result.unshift(",");
    }
  }
  return result.join("");
};

// form Email Validation service
export const checkFormatEmail = (str: string) => {
  if (str === "") {
    return true;
  }
  // const filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/;
  const filter = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  if (filter.test(str)) {
    return true;
  } else {
    return false;
  }
};

// password validation
export const checkFormatPassword = (str: string) => {
  if (str === "") {
    return true;
  }
  const filter =
    /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,20}$/;
  return filter.test(str);
};

// url validation
export const isUrlValid = (str: string) => {
  if (str === "") {
    return false;
  }

  try {
    new URL(str);
  } catch {
    return false;
  }
  return true;
};
