import React from "react";
import { useTranslation } from "react-i18next";

import { LocationData } from "../../../../../models/LocationData";
import { CreateLocalPostState } from "../../../CreateLocalPostForm/utils";
import { convertLocalPostToStringSummary } from "../utils";

import "./styles.scss";

interface IInstagramPostPreviewProps {
  localPost: CreateLocalPostState;
  locationData: LocationData | undefined;
  imgSrc: string;
}

export const InstagramPostPreview: React.FunctionComponent<
  IInstagramPostPreviewProps
> = ({ localPost, locationData, imgSrc }: IInstagramPostPreviewProps) => {

  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`analysisCollectionsPage.modalCreateLocalPost.instagramPostPreview.${key}`);

  const instagramAccountName = locationData?.locations_instagram_integrations?.[0]?.username ?? t("no_username");

  return (
    <div className="instagram-post-preview">
      <div className="title-bar">
        <div className="left-side">
          <span className="account-name">{instagramAccountName}</span>
        </div>
        <div className="options"></div>
      </div>
      <div className="image-wrapper">
        <img src={imgSrc} alt="to-be-uploaded"></img>
      </div>
      <div className="buttons-bar">
        <div className="left-side">
          <div className="instagram-post-preview-icons like"></div>
          <div className="instagram-post-preview-icons comment"></div>
          <div className="instagram-post-preview-icons share"></div>
        </div>
        <div className="instagram-post-preview-icons bookmark"></div>
      </div>
      <p className="text-wrapper">
        <span className="account-name">{instagramAccountName} </span>
        <span className="summary">{convertLocalPostToStringSummary(localPost)}</span>
      </p>
      <div className="add-a-comment-prompt">
        <span>{t("add_a_comment")}</span>
      </div>
      <div className="bottom-border"></div>
    </div>
  );
};
