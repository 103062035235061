/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";
import { OrganizationData } from "../../../models/OrganizationData";

export interface IModalViewOrgScreensProps {
  orgData: OrganizationData;
  onClose: () => void;
}

const screens = (orgId: string, t: (key: string) => string) => [
  {
    name: t("dashboard"),
    link: `/dashboard/organization/${orgId}`,
  },
  {
    name: t("analysis"),
    link: `/analysis/organization/${orgId}/collections`,
  },
  {
    name: t("facility_list"),
    link: `/locations/organization/${orgId}`,
  },
  {
    name: t("suggestions"),
    link: `/recommendations/organization/${orgId}/`,
  },
  {
    name: t("rec_messages"),
    link: `/recMessages/organization/${orgId}/`,
  },
];

export const ModalViewOrgScreens: React.FunctionComponent<
  IModalViewOrgScreensProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`organizationManagementPage.modalViewOrgScreens.${key}`);
  const menuT = (key: string) => _t(`leftMenu.${key}`);

  const { orgData, onClose } = props;

  return (
    <div className="modal modal-default modal-view-org-screens">
      <div className="modal-mains">
        <a className="btn-close" onClick={onClose}></a>
        <div className="top-title flex-grid">{t("see_screens")}</div>
        <p className="modal-view-org-screens_subtitle">
          {t("subject")}：{orgData.name}
        </p>
        <ul className="modal-view-org-screens_links">
          {screens(orgData.id, menuT).map((screen) => (
            <li key={screen.name}>
              <a
                target="_blank"
                href={screen.link}
                className={`nav-items`}
                rel="noreferrer"
              >
                <span className="txt">{screen.name}</span>
              </a>
            </li>
          ))}
        </ul>
        <div className="bottom-btns">
          <a className="btn btn-border" onClick={onClose}>
            {t("cancel")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ModalViewOrgScreens;
