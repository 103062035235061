import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { InstagramPostPreview } from "./InstagramPostPreview";
import { LocalPostPreview } from "./LocalPostPreview";
import { LocationData } from "../../../../models/LocationData";
import { modalViewGAEvent } from "../../../../utils/ga";
import { CreateLocalPostState } from "../../CreateLocalPostForm/utils";
import "./styles.scss";

interface IModalPreviewLocalPost {
  localPost: CreateLocalPostState;
  locationData: LocationData | undefined;
  onClose: () => void;
  setShownModalConfirm: (value: boolean) => void;
}

export const ModalPreviewLocalPost: React.FunctionComponent<
  IModalPreviewLocalPost
> = ({ localPost, onClose, locationData, setShownModalConfirm }) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`analysisCollectionsPage.modalCreateLocalPost.modalPreview.${key}`);

  const src = React.useMemo<string>(() => {
    if (localPost.media.length === 0) {
      return "";
    }
    return URL.createObjectURL(localPost.media[0]);
  }, [localPost]);

  useEffect(() => {
    modalViewGAEvent("previewLocalPost");
  }, []);

  return (
    <div className="modal-default modal-preview-local-post">
      <div className="modal-mains">
        <div className="top-title">{t("title")}</div>
        <button className="btn-close" onClick={onClose}></button>
        <div className="modal-mains__body">
          <div className="post-preview-wrapper">
            <h2>{t("gbp")}</h2>
            <LocalPostPreview
              localPost={localPost}
              locationData={locationData}
              imgSrc={src}
            />
          </div>
          {localPost.postToInstagram && (
            <div className="post-preview-wrapper">
              <h2>{t("instagram")}</h2>
              <InstagramPostPreview
                localPost={localPost}
                locationData={locationData}
                imgSrc={src}
              />
            </div>
          )}
          <div className="bottom-btns">
            <button className="btn btn-border" onClick={onClose}>
              {t("edit")}
            </button>
            <button
              className={`btn btn-blue`}
              onClick={() => {
                setShownModalConfirm(true);
              }}
            >
              {t("create")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
