import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  SummaryLocation,
  ISelectableSummaryLocation,
} from "../../../containers/SendMessageScreens";
import "./styles.scss";
import { Checkbox } from "../../FormElement/Checkbox";

export interface ISummaryLocationProps {
  locations: Array<SummaryLocation>;
  isSelectable?: boolean;
  onSelectedLocations?: (selectedLocationds: Array<SummaryLocation>) => void;
  hideFootnote?: boolean;
  allSelectedByDefault?: boolean;
}

const SummaryLocationList: React.FunctionComponent<ISummaryLocationProps> = ({
  locations,
  isSelectable = false,
  onSelectedLocations,
  hideFootnote,
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`modalSendMessage.${key}`);

  const [selectedLocationds, setSelectedLocations] = useState<
    ISelectableSummaryLocation[]
  >([]);

  useEffect(() => {
    if (locations && locations.length > 0) {
      setSelectedLocations(
        locations.map((l) => {
          return { summaryLocation: l, isSelected: true };
        })
      );
    }
  }, [locations]);

  const clearAll = () => {
    setSelectedLocations(
      selectedLocationds.map((sl) => ({ ...sl, isSelected: false }))
    );
    onSelectedLocations && onSelectedLocations([]);
  };

  const selectAll = () => {
    setSelectedLocations(
      selectedLocationds.map((sl) => ({ ...sl, isSelected: true }))
    );
    onSelectedLocations && onSelectedLocations(locations);
  };

  const renderLocationsList = () => {
    if (locations.length === 0) {
      return <span className="empty">{t("location_list_empty")}</span>;
    }

    if (isSelectable && onSelectedLocations && selectedLocationds.length > 1) {
      return selectedLocationds.map(({ summaryLocation, isSelected }) => {
        return (
          <Checkbox
            checked={isSelected}
            label={summaryLocation.name}
            isSelectAll={false}
            id={`checkbox-select-all-${summaryLocation.id}`}
            key={summaryLocation.id}
            onChange={(checked: boolean) => {
              const changedLocations = selectedLocationds.map((sl) => {
                if (sl.summaryLocation.id === summaryLocation.id) {
                  return {
                    summaryLocation: sl.summaryLocation,
                    isSelected: checked,
                  };
                }
                return sl;
              });

              setSelectedLocations(
                changedLocations as ISelectableSummaryLocation[]
              );
              onSelectedLocations(
                changedLocations
                  .filter((cl) => cl.isSelected)
                  .map((i) => i.summaryLocation)
              );
            }}
          />
        );
      });
    }

    return locations.map(({ id, name }) => <span key={id}>{name}</span>);
  };

  return (
    <>
      <div className="summaryLocationList">
        <div className="locations">{renderLocationsList()}</div>
        <div className="clear-all-button-wrapper">
          <button className="btn btn-blue" onClick={selectAll}>
            {t("select_all")}
          </button>
          <button className="btn btn-border" onClick={clearAll}>
            {t("clear_all")}
          </button>
        </div>
      </div>
      {!hideFootnote && (
        <small className="summaryLocationList-footnote">
          {t("message_not_sent_to_locs_without_admins")}
        </small>
      )}
    </>
  );
};

export default SummaryLocationList;
