import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";
import React, { useState } from "react";

import BottomMenu from "../../components/BottomMenu";
import { Header } from "../../components/Header";
import { LeftMenu } from "../../components/LeftMenu";
import LineButton from "../../components/LineButton";
import ModalConfirm from "../../components/ModalConfirm";
import ModalPopupMenu from "../../components/ModalPopupMenu";
import ModalPrivacyPolicyAndTermsService from "../../components/ModalPrivacyPolicyAndTermsService";
import { useMe } from "../../hooks/queries";
import { AccountData, AccountType } from "../../models/AccountData";
import { getLeftNavigationExpandedState } from "../../utils/localStorage";
import "./styles.scss";

export interface NavigationContainerProps {
  children: any;
}

enum ForcedLogoutState {
  OrgUserWithoutOrg = "org_user_without_org",
  OrganizationExpired = "organization_expired",
}

export const checkForForcedLogout = (
  me: AccountData | undefined
): ForcedLogoutState | undefined => {
  if (!me) {
    return;
  }

  // invalid org users are forced to logout
  if (me.type === AccountType.ORG_ADMIN && !me.organization) {
    return ForcedLogoutState.OrgUserWithoutOrg;
  }

  if (me.organization?.plan_expiry_date) {
    // truncate the expiry date（UTC）based with JST
    const expday = dayjs(me.organization.plan_expiry_date)
      .add(-9, "hours")
      .add(1, "days")
      .minute(0)
      .second(0)
      .millisecond(0);
    const today = dayjs();
    if (expday.isBefore(today)) {
      return ForcedLogoutState.OrganizationExpired;
    }
  }
};

const NavigationContainer: React.FunctionComponent<
  NavigationContainerProps
> = ({ children }) => {
  const { logout } = useAuth0();
  const { data: me } = useMe();

  const [leftNavigationExpanded, setLeftNavigationExpanded] = useState<boolean>(
    getLeftNavigationExpandedState()
  );
  const [popupMenuOpen, setPopupMenuOpen] = useState<boolean>(false);

  // on Change Expand
  const onChangeExpand = () => {
    setLeftNavigationExpanded(!leftNavigationExpanded);
    localStorage.setItem(
      "leftNavigationExpanded",
      (!leftNavigationExpanded).toString()
    );
  };

  const [
    modalPrivacyPolicyAndTermsServiceType,
    setModalPrivacyPolicyAndTermsServiceType,
  ] = useState<string>(""); // ''
  const [shownModalConfirmLogout, setShownModalConfirmLogout] =
    useState<boolean>(false); // false

  const forcedLogoutState = checkForForcedLogout(me);

  return (
    <React.Fragment>
      <Header
        setModalPrivacyPolicyAndTermsServiceType={
          setModalPrivacyPolicyAndTermsServiceType
        }
        setShownModalConfirmLogout={setShownModalConfirmLogout}
      />
      <div className="page-content">
        <div
          className={`content-wrap flex ${
            leftNavigationExpanded ? "open-left" : ""
          }`}
        >
          {
            // LeftMenu is not displayed when the user is not logged in
            me && <LeftMenu onChangeExpand={onChangeExpand} />
          }
          {children}
          {
            // BottomMenu is not displayed when the user is not logged in
            me && (
              <BottomMenu
                onOpenMenu={() => {
                  setPopupMenuOpen(true);
                }}
              />
            )
          }
          <LineButton />
        </div>
      </div>
      {popupMenuOpen && (
        <ModalPopupMenu
          onClose={() => setPopupMenuOpen(false)}
          setModalPrivacyPolicyAndTermsServiceType={
            setModalPrivacyPolicyAndTermsServiceType
          }
          setShownModalConfirmLogout={setShownModalConfirmLogout}
        />
      )}

      {modalPrivacyPolicyAndTermsServiceType !== "" && (
        <ModalPrivacyPolicyAndTermsService
          type={modalPrivacyPolicyAndTermsServiceType}
          onClose={() => {
            setModalPrivacyPolicyAndTermsServiceType("");
          }}
        />
      )}

      {shownModalConfirmLogout && (
        <ModalConfirm
          title={"logout"}
          description={"are_you_sure_to_log_out"}
          cancelLabel={"cancel"}
          confirmLabel={"logout"}
          onClose={() => {
            setShownModalConfirmLogout(false);
          }}
          onConfirm={() => {
            logout({ returnTo: window.location.origin });
            setShownModalConfirmLogout(false);
          }}
        />
      )}

      {forcedLogoutState === ForcedLogoutState.OrgUserWithoutOrg && (
        <ModalConfirm
          title={"user_does_not_belong_to_any_org"}
          description={"user_needs_to_belong_to_an_org"}
          cancelLabel={""}
          confirmLabel={"ok"}
          onClose={() => {
            logout({ returnTo: window.location.origin });
          }}
          onConfirm={() => {
            logout({ returnTo: window.location.origin });
          }}
        />
      )}

      {forcedLogoutState === ForcedLogoutState.OrganizationExpired && (
        <ModalConfirm
          title={"organization_is_expired"}
          description={"unfortunately_your_organization_is_expired"}
          cancelLabel={""}
          confirmLabel={"ok"}
          onClose={() => {
            logout({ returnTo: window.location.origin });
          }}
          onConfirm={() => {
            logout({ returnTo: window.location.origin });
          }}
        />
      )}
    </React.Fragment>
  );
};

export default NavigationContainer;
