import React from "react";
import { useTranslation } from "react-i18next";

import { Checkbox } from "../../FormElement/Checkbox";
import { IIfOrConditionItem } from "../ModalFilterCondition";

export interface IIfOrConditionProps {
  isBlueTitle: boolean;
  item: IIfOrConditionItem;
  changeCheckbox: (key: string, nextValue: boolean | undefined) => void;
  disabled?: boolean;
  i18Key?: string;
}

export const IfOrCondition: React.FunctionComponent<IIfOrConditionProps> = (
  props
) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`${props?.i18Key ?? 'facilityListPage.modalFilterCondition.profBoolLabels.'}${key}`);

  const {
    disabled = false,
    isBlueTitle,
    item: { key, shouldInclude },
    changeCheckbox,
  } = props;

  return (
    <div className={`check-groups ${isBlueTitle ? "" : "mb-54"}`}>
      <div className={`blue-block-row flex ${isBlueTitle ? "" : "mb-14"}`}>
        <div className={`${isBlueTitle ? "blue-block" : "bold-title"}`}>
          {t(`${key}.label`)}
        </div>
      </div>
      <div className="check-list">
        <ul>
          <li key={"true"}>
            <Checkbox
              disabled={disabled}
              checked={shouldInclude === true}
              label={t(`${key}.true`)}
              id={`checkbox-${key}-true`}
              onChange={(checked: boolean) => {
                if (shouldInclude === checked) {
                  return;
                } // No change
                changeCheckbox(key, checked ? true : undefined);
              }}
            />
          </li>
          <li key={"false"}>
            <Checkbox
              disabled={disabled}
              checked={shouldInclude === false}
              label={t(`${key}.false`)}
              id={`checkbox-${key}-false`}
              onChange={(checked: boolean) => {
                if (shouldInclude === false && checked) {
                  return;
                } // No change
                changeCheckbox(key, checked ? false : undefined);
              }}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default IfOrCondition;
