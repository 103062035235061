import { Buffer } from "buffer";
import dayjs from "dayjs";
import React, { Reducer, useReducer, useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import { GenerateAiTextButton } from "./GenerateAiTextButton";
import { TopicTypeSelect } from "./TopicTypeSelect";
import {
  ActionType,
  CreateLocalPostAction,
  CreateLocalPostActionTypes,
  CreateLocalPostState,
  EventScheduleError,
  LocalPostTopicType,
  createLocalPostStateReducer,
  isCreateLocalPostStateValid,
} from "./utils";
import { LocationData } from "../../../models/LocationData";
import DataSvc from "../../../services/dataSvc";
import Checkbox from "../../FormElement/Checkbox";
import { DateTimeIntervalInput } from "../../FormElement/DateTimeIntervalInput";
import { DateTimeInterval } from "../../FormElement/DateTimeIntervalInput/utils";
import DropdownSelect from "../../FormElement/DropdownSelect";
import InputBox from "../../FormElement/InputBox";
import InputTextArea from "../../FormElement/InputTextarea";
import { MediaUpload } from "../../FormElement/MediaUpload";
import ModalConfirm from "../../ModalConfirm";
import { ModalPreviewLocalPost } from "../ModalCreateLocalPost/ModalPreviewLocalPost";

import "./styles.scss";

export interface ICreateLocalPostFormProps {
  locationData: LocationData | undefined;
  post: (value: CreateLocalPostState) => void;
  enablePostToInstagram?: boolean;
  canGenerateAiText?: boolean;
  onClose?: () => void;
  locationNames: string[];
}

export const CreateLocalPostForm: React.FunctionComponent<
  ICreateLocalPostFormProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(
      `analysisCollectionsPage.modalCreateLocalPost.createLocalPostForm.${key}`
    );

  const {
    locationData,
    post,
    onClose,
    canGenerateAiText,
    enablePostToInstagram,
  } = props;
  const hasPrimaryPhone = locationData?.pickup_fields?.has_primary_phone;
  const canPostInstagram =
    (locationData?.locations_instagram_integrations?.length ?? 0) > 0;

  const [shownModalConfirm, setShownModalConfirm] = useState<boolean>(false);

  const [shownModalPreview, setShownModalPreview] = useState<boolean>(false);

  const [state, dispatch] = useReducer<
    Reducer<CreateLocalPostState, CreateLocalPostAction>
  >(createLocalPostStateReducer, {
    media: [],
    text: "",
    messageTextError: undefined,
    languageCode: "ja",
    callToAction: {
      actionType: ActionType.ACTION_TYPE_UNSPECIFIED,
      url: "",
      urlError: undefined,
    },
    topicType: LocalPostTopicType.STANDARD,
    event: {
      title: "",
      schedule: {
        startDate: undefined,
        startTime: undefined,
        endDate: undefined,
        endTime: undefined,
      },
      scheduleError: {
        dateError: undefined,
        timeError: undefined,
      },
    },
    offer: {
      couponCode: "",
      redeemOnlineUrl: "",
      termsConditions: "",
    },
    postToInstagram: false,
  });

  const {
    media,
    mediaError,
    text,
    messageTextError,
    callToAction,
    topicType,
    event,
    offer,
  } = state;

  const onChangeTopicType = (topicType: LocalPostTopicType) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_TOPIC_TYPE,
      payload: topicType,
    });
  };

  const onChangeMedia = (files: File[]) => {
    dispatch({ type: CreateLocalPostActionTypes.CHANGE_MEDIA, payload: files });
  };

  const onChangeMediaError = (error: string | undefined) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_MEDIA_ERROR,
      payload: error,
    });
  };

  const onChangeEventTitle = (eventTitle: string) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_EVENT_TITLE,
      payload: eventTitle,
    });
  };

  const onChangeEventSchedule = (eventSchedule: DateTimeInterval) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_EVENT_SCHEDULE,
      payload: eventSchedule,
    });
  };

  const onChangeEventScheduleError = (
    eventScheduleError: EventScheduleError
  ) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_EVENT_SCHEDULE_ERROR,
      payload: eventScheduleError,
    });
  };

  const onChangeBody = (body: string) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_BODY,
      payload: body,
    });
  };

  const onChangeOfferCouponCode = (couponCode: string) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_OFFER_COUPON_CODE,
      payload: couponCode,
    });
  };

  const onChangeOfferRedeemOnlineUrl = (redeemOnlineUrl: string) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_OFFER_REDEEM_ONLINE_URL,
      payload: redeemOnlineUrl,
    });
  };

  const onChangeOfferTermsAndConditions = (termsAndConditions: string) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_OFFER_TERMS_CONDITIONS,
      payload: termsAndConditions,
    });
  };

  const onChangeCallToActionType = (actionType: ActionType) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_CALL_TO_ACTION_TYPE,
      payload: actionType,
    });
  };

  const onChangeCallToActionUrl = (url: string) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_CALL_TO_ACTION_URL,
      payload: url,
    });
  };

  const onChangePostToInstagram = (postToInstagram: boolean) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_POST_TO_INSTAGRAM,
      payload: postToInstagram,
    });
  };

  const onTriggerGenerateText = async () => {
    if (!canGenerateAiText) {
      return;
    }
    if (media.length === 0) {
      return;
    }
    const image = media[0];
    const mediaBuffer = await image.arrayBuffer();
    const imageDataString = Buffer.from(mediaBuffer).toString("base64");

    const data: any = {
      location_id: Number(locationData?.id),
      mime_type: image.type,
      image_data_string: imageDataString,
    };

    if (state.topicType !== LocalPostTopicType.STANDARD) {
      data.topic = state.topicType;
      data.extra_information = {
        title: state.event.title,
        start_date: state.event.schedule.startDate?.format("YYYY/MM/DD HH:mm"),
        end_date: state.event.schedule.endDate?.format("YYYY/MM/DD HH:mm"),
      };
    }

    ReactGA.event({
      category: "create-local-post",
      action: "Generate description with AI",
      label: state.topicType,
    });

    const response = await DataSvc.generateLocalPostTextWithGemini(data);
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_BODY,
      payload: response,
    });
  };

  return (
    <div>
      <div className="create-local-post-form">
        <TopicTypeSelect
          changeTopicType={onChangeTopicType}
          locationData={locationData}
          topicType={topicType}
        />
        <div className="media-upload-wrapper">
          <h3>{t("add_photo")}</h3>
          <MediaUpload
            files={media}
            changeFiles={onChangeMedia}
            error={mediaError}
            changeError={onChangeMediaError}
            maxFiles={1}
            maxSizeMiB={10}
            minWidth={400}
            minHeight={300}
          />
        </div>
        {(topicType === LocalPostTopicType.EVENT ||
          topicType === LocalPostTopicType.OFFER) && (
          <div className="event">
            <h3>{t(`title.${topicType}`)}</h3>
            <InputBox
              name={"eventTitle"}
              value={event.title}
              placeholder={t(`title_placeholder.${topicType}`)}
              onChange={onChangeEventTitle}
              errorLabel={event.titleError}
              classNameContainer="margin-bottom-30"
            />
            <DateTimeIntervalInput
              value={event.schedule}
              onChange={onChangeEventSchedule}
              error={event.scheduleError}
              onChangeError={onChangeEventScheduleError}
              hideTimeOfDay={topicType === LocalPostTopicType.OFFER}
              minDate={MIN_DATE}
              maxDate={GET_MAX_DATE()}
            />
          </div>
        )}
        <GenerateAiTextButton
          canGenerateAiText={canGenerateAiText}
          localPostState={state}
          onClick={onTriggerGenerateText}
        />
        <div className="message-body">
          <h3>{t(`message_body.${topicType}`)}</h3>
          <InputTextArea
            name="messageText"
            value={text}
            placeholder={t(`message_body_placeholder.${topicType}`)}
            classNameContainer={"message-body-input margin-bottom-30"}
            errorLabel={messageTextError}
            onChange={onChangeBody}
            maxChars={1500}
            showChars={true}
          />
        </div>
        {topicType === LocalPostTopicType.OFFER && (
          <div className="offer">
            <h3>{t("offer.coupon_code")}</h3>
            <InputBox
              name={"couponCode"}
              value={offer.couponCode}
              placeholder={t("offer.coupon_code_placeholder")}
              onChange={onChangeOfferCouponCode}
              errorLabel={offer.couponCodeError}
              classNameContainer="margin-bottom-30"
            />
            <h3>{t("offer.redeem_online_url")}</h3>
            <InputBox
              name={"redeemOnlineUrl"}
              value={offer.redeemOnlineUrl}
              placeholder={t("offer.redeem_online_url_placeholder")}
              onChange={onChangeOfferRedeemOnlineUrl}
              errorLabel={offer.redeemOnlineUrlError}
              classNameContainer="margin-bottom-30"
            />
            <h3>{t("offer.terms_and_conditions")}</h3>
            <div className="message-body">
              <InputTextArea
                name="termsAndConditions"
                value={offer.termsConditions}
                placeholder={t("offer.terms_and_conditions_placeholder")}
                classNameContainer={"message-body-input margin-bottom-30"}
                errorLabel={offer.termsAndConditionsError}
                onChange={onChangeOfferTermsAndConditions}
                maxChars={5000}
                showChars={true}
              />
            </div>
          </div>
        )}
        {topicType !== LocalPostTopicType.OFFER && (
          <div className="call-to-action">
            <h3>{t("add_button")}</h3>
            <DropdownSelect
              isTranslation={true}
              translationKey="analysisCollectionsPage.modalCreateLocalPost.createLocalPostForm.actionTypes"
              value={callToAction.actionType}
              items={
                hasPrimaryPhone
                  ? Object.values(ActionType).map((item) => item.toString())
                  : Object.values(ActionType)
                      .filter((item) => item !== ActionType.CALL)
                      .map((item) => item.toString())
              }
              onChange={onChangeCallToActionType}
            />
            {callToAction.actionType !== ActionType.ACTION_TYPE_UNSPECIFIED &&
              callToAction.actionType !== ActionType.CALL && (
                <div className="url-wrapper">
                  <h3>{t("url")}</h3>
                  <InputBox
                    value={callToAction.url}
                    onChange={onChangeCallToActionUrl}
                    errorLabel={callToAction.urlError}
                    placeholder={t("url_placeholder")}
                  />
                </div>
              )}
          </div>
        )}
        {enablePostToInstagram && (
          <div className="post-to-instagram-wrapper">
            {!canPostInstagram && (
              <div className="disable-overlay">
                {t("not_connected_to_instagram")}
              </div>
            )}
            <div className="post-to-instagram">
              <Checkbox
                checked={state.postToInstagram}
                disabled={media.length === 0 || !canPostInstagram}
                errorLabel={
                  media.length === 0 && canPostInstagram
                    ? t("post_to_instagram_error")
                    : undefined
                }
                onChange={onChangePostToInstagram}
                label={t("post_to_instagram")}
                id={"post-to-instagram"}
              />
              {state.postToInstagram && (
                <span className="post-to-instagram-call-to-action-note">
                  {topicType === LocalPostTopicType.OFFER
                    ? t("post_to_instagram_terms_and_conditions_note")
                    : t("post_to_instagram_call_to_action_note")}
                </span>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="create-local-post-warning">
        <span>{t("post_warning")}</span>
      </div>
      <div className="create-local-post-bottom-btns bottom-btns">
        <div>
          {onClose && (
            <button className="btn btn-border" onClick={onClose}>
              {t("cancel")}
            </button>
          )}
        </div>
        <div>
          <button
            className={`btn btn-border ${
              isCreateLocalPostStateValid(state) ? "" : "disabled"
            }`}
            onClick={() => {
              setShownModalPreview(true);
            }}
          >
            {t("preview")}
          </button>
          <button
            className={`btn btn-blue ${
              isCreateLocalPostStateValid(state) ? "" : "disabled"
            }`}
            onClick={() => {
              setShownModalConfirm(true);
            }}
          >
            {t("create")}
          </button>
        </div>
      </div>
      {shownModalPreview && (
        <ModalPreviewLocalPost
          onClose={() => {
            setShownModalPreview(false);
          }}
          localPost={state}
          locationData={locationData}
          setShownModalConfirm={setShownModalConfirm}
        />
      )}
      {shownModalConfirm && (
        <ModalConfirm
          title={"please_confirm_to_create_local_post"}
          cancelLabel={"cancel"}
          confirmLabel={"post"}
          onClose={() => {
            setShownModalConfirm(false);
          }}
          onConfirm={() => {
            post(state);
            setShownModalConfirm(false);
          }}
        >
          <span>{t("post_to_these_locations")}</span>
          <ul className="location-names-list">
            {props.locationNames?.map((locationName) => (
              <li key={locationName}>{locationName}</li>
            ))}
          </ul>
        </ModalConfirm>
      )}
    </div>
  );
};

const MIN_DATE = dayjs("1970-01-01");
const GET_MAX_DATE = () => {
  const now = dayjs();
  return now.add(1, "year");
};
