import React from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import "./styles.scss";

const InstagramDeletedPage: React.FunctionComponent = () => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`instagramDeletedPage.${key}`);

  return (
    <div className="right-content instagram-integrate">
      <div className="top-title-bar flex-grid">
        <div className="left-title">{t("instagram_integration_deleted")}</div>
      </div>
      <p>
        <strong>{t("instagram_integration_deleted_description")}</strong>
      </p>
    </div>
  );
};

export default withRouter(InstagramDeletedPage);
