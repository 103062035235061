/* eslint-disable jsx-a11y/anchor-is-valid */
import dayjs from "dayjs";
import _, { flatten, pickBy } from "lodash";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { BarChart } from "./BarChart";
import { CircleChart } from "./CircleChart";
import { DownloadCsvButton } from "./Download";
import { LineChart } from "./LineChart";
import { TableChart } from "./TableChart";
import {
  useCategories,
  useLabels,
  useLocationMunis,
  useMe,
} from "../../../hooks/queries";
import {
  ChartData,
  AnalysisChartFilterData,
  OrgTimeSeriesMetricsData,
  OrgGroupAggregationMetricsData,
  OrgGroupedTimeSeriesMetricsData,
} from "../../../models/ChartCollectionData";
import {
  FacilityFilterConditionData,
  createDefaultConditionFormData,
} from "../../../models/FacilityFilterConditionData";
import { MunicipalityGroupData } from "../../../models/MunicipalityData";
import DataSvc from "../../../services/dataSvc";
import { getFromToDate } from "../../../utils/chartPeriodUtils";
import { ModalFilterCondition } from "../../FacilityListComponents/ModalFilterCondition";
import { DateRangeArea } from "../DateRangeArea";

import "./styles.scss";

export interface ICollectionChartAreaProps extends RouteComponentProps<any> {
  indivFacilityMode?: boolean;
  chartData: ChartData;
  totalLength: number;
  chartIndex: number;
  onClickCopyChart: () => void;
  onClickEditChart: () => void;
  onClickMoveUp: () => void;
  onClickMoveDown: () => void;
  onUpdate: (id: number, payload: any) => void;
}

const defaultConditionFormData = createDefaultConditionFormData();

export const CollectionChartArea: React.FunctionComponent<
  ICollectionChartAreaProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`analysisCollectionsPage.collectionChartArea.${key}`);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    chartData,
    totalLength,
    chartIndex,
    indivFacilityMode,
    onClickEditChart,
    onClickMoveUp,
    onClickMoveDown,
    onUpdate,
  } = props;

  // Only for line chart
  const [timeUnit, setTimeUnit] = useState<"day" | "week" | "month">("day");
  const [usePrevYear, setUsePrevYear] = useState<boolean>(false);

  const [shownModalFilterCondition, setShownModalFilterCondition] =
    useState<boolean>(false); // false

  const [conditionFormData, setConditionFormData] =
    useState<FacilityFilterConditionData>(defaultConditionFormData);

  const [chartDataShown, setChartDataShown] = useState<ChartData>({
    name: "",
    collection_id: 0,
    template_id: 0,
    template_name: "",
    period_start: "",
    period_end: "",
    period_option: "custom",
    type: "bar",
    compare_to_prev: false,
    time_unit: "day",
    metrics: "",
    aggregation: "sum",
    grouping1: "",
    grouping2: "",
    prefecture_filter: "",
    municipality_filter: "",
    category_filter: "",
    label_filter: "",
    display_order: 0,
    is_template: true,
    id: 0,
    created_at: "",
    created_by: 1,
    last_modified_at: "",
    last_modified_by: 1,
  });
  const [chartDetailsData, setChartDetailsData] =
    useState<OrgGroupAggregationMetricsData>({
      organization_id: 0,
      start_date: "",
      end_date: "",
      data: [],
    });

  const [chartDetailsForLinesData, setChartDetailsForLinesData] = useState<
    OrgTimeSeriesMetricsData[]
  >([]);
  const [
    prevYearChartDetailsForLinesData,
    setPrevYearChartDetailsForLinesData,
  ] = useState<OrgTimeSeriesMetricsData[]>([]);

  const [organizationId, setOrganizationId] = useState<string>();

  const { data: me } = useMe();

  useEffect(() => {
    if (!me?.organization) {
      return;
    }

    setOrganizationId(me.organization.id.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  const { data: labels } = useLabels(
    pickBy({
      organization_id: organizationId,
    })
  );
  const { data: categories } = useCategories();
  const { data: municipalities } = useLocationMunis(
    me?.id,
    organizationId || ""
  );

  useEffect(() => {
    if (labels && chartData.label_filter) {
      const ids = chartData.label_filter.split(",");
      setConditionFormData((prev) => ({
        ...prev,
        labels: labels.filter((l) => ids.indexOf(l.id.toString()) >= 0),
      }));
    }
  }, [labels, chartData]);

  useEffect(() => {
    if (categories && chartData.category_filter) {
      const ids = chartData.category_filter.split(",");
      setConditionFormData((prev) => ({
        ...prev,
        categories: categories.filter((c) => ids.indexOf(c.id.toString()) >= 0),
      }));
    }
  }, [categories, chartData]);

  useEffect(() => {
    if (municipalities && chartData.municipality_filter) {
      const ids = chartData.municipality_filter.split(",");
      const prefArray = municipalities.filter((item) => {
        return item.type === "pref";
      });

      const municipalitiesArray: MunicipalityGroupData[] = [];

      prefArray.forEach((itemPref) => {
        const muniArray = municipalities.filter((itemMuni) => {
          return (
            itemMuni.type === "muni" &&
            itemMuni.parent_external_id === itemPref.external_id
          );
        });

        municipalitiesArray.push({
          name: itemPref.name,
          muniList: muniArray.filter((m) => ids.indexOf(m.id.toString()) >= 0),
        });
      });

      setConditionFormData((prev) => ({
        ...prev,
        municipalities: municipalitiesArray,
      }));
    }
  }, [municipalities, chartData]);

  useEffect(() => {
    if (!!chartData) {
      setChartDataShown(_.cloneDeep(chartData));
      setTimeUnit(chartData.time_unit);
      setUsePrevYear(chartData.compare_to_prev);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  useEffect(() => {
    if (chartDataShown.collection_id !== 0) {
      let chartDetailsDataTemp = chartDetailsData;
      const chartDetailsForLinesDataTemp: OrgTimeSeriesMetricsData[] = [];
      const prevYearChartDetailsForLinesDataTemp: OrgTimeSeriesMetricsData[] =
        [];

      const lineMetricsArray = JSON.parse(chartDataShown.metrics);

      const groupArray: string[] = [];
      if (chartDataShown.grouping1) {
        groupArray.push(chartDataShown.grouping1);
      }

      if (
        chartDataShown.grouping2 &&
        ["table", "pie"].includes(chartDataShown.type)
      ) {
        groupArray.push(chartDataShown.grouping2);
      }

      const { fromDateStr, toDateStr } = getFromToDate(
        chartDataShown.period_option
      );

      const getStartDate = () => {
        if (chartDataShown.period_option === "custom") {
          return chartDataShown.period_start;
        } else {
          return fromDateStr
            ? fromDateStr.toISOString()
            : moment().endOf("day").subtract(20, "days").toISOString();
        }
      };

      const getEndDate = () => {
        if (chartDataShown.period_option === "custom") {
          return chartDataShown.period_end;
        } else {
          return toDateStr
            ? toDateStr.toISOString()
            : moment().endOf("day").subtract(7, "days").toISOString();
        }
      };

      // Default: last relevant 14 days
      const requestParams = {
        organization_id: organizationId ? parseInt(organizationId) : null,
        start_date: getStartDate(),
        end_date: getEndDate(),
        municipality_id: chartDataShown.municipality_filter?.split(","),
        category_id: chartDataShown.category_filter?.split(","),
        label_id: chartDataShown.label_filter?.split(","),
        aggregation: chartDataShown.aggregation,
        group: groupArray,
      };

      const fetchChartsData = async () => {
        switch (chartDataShown.type) {
          case "line":
            const requestParameter = {
              ...requestParams,
              ...(chartDataShown.location_id
                ? { location_id: [chartDataShown.location_id] }
                : {}),
            };

            let groupedResponse: OrgGroupedTimeSeriesMetricsData;
            let singleResponse: OrgTimeSeriesMetricsData;

            // Line graphs can have single calls of single data (eg: simple line of overall impressions)
            // Single calls of multiple data (line graph of top 5 municipalities which only calls the API once),
            // Or multiple calls of single data (eg. impressions by impression type - where individual lines are called once to the API)
            // lineMetricsArray = API calls to be made. The Group data handles what happens when data comes back as a group.
            // Promise.all is returned as a result first to preserve the order of promises and therefore consistency in order and color of lines.
            const result = await Promise.all(
              // need update
              lineMetricsArray.map(async (item: any) => {
                return await DataSvc.getChartDataByTimeSeries({
                  ...requestParameter,
                  metric: item,
                });
              })
            );
            result.forEach((response: any, index) => {
              // Grouped data
              if (response.data[0] && Array.isArray(response.data[0].data)) {
                groupedResponse = response;
                const { data, organization_id, end_date, start_date } =
                  groupedResponse;
                data.forEach((item) => {
                  chartDetailsForLinesDataTemp.push({
                    title: [item.label],
                    organization_id,
                    start_date,
                    end_date,
                    data: item.data,
                  });
                });
              } else {
                singleResponse = response;
                chartDetailsForLinesDataTemp.push({
                  ...response,
                  title: lineMetricsArray[index],
                });
              }
            });

            if (usePrevYear) {
              const prevYearResults = await Promise.all(
                lineMetricsArray.map(async (item: any) => {
                  const { start_date, end_date } = requestParams;
                  return await DataSvc.getChartDataByTimeSeries({
                    ...requestParameter,
                    start_date: dayjs(start_date)
                      .subtract(1, "year")
                      .toISOString(),
                    end_date: dayjs(end_date).subtract(1, "year").toISOString(),
                    metric: item,
                  });
                })
              );

              prevYearResults.forEach((response: any, index) => {
                // Grouped data
                if (response.data[0] && Array.isArray(response.data[0].data)) {
                  const prevYearGroupedResponse =
                    response as OrgGroupedTimeSeriesMetricsData;
                  const { data, organization_id } = prevYearGroupedResponse;
                  data.forEach((item) => {
                    prevYearChartDetailsForLinesDataTemp.push({
                      title: [item.label],
                      organization_id,
                      start_date: groupedResponse.start_date,
                      end_date: groupedResponse.end_date,
                      data: item.data,
                    });
                  });
                } else {
                  prevYearChartDetailsForLinesDataTemp.push({
                    ...response,
                    title: lineMetricsArray[index],
                    start_date: singleResponse.start_date,
                    end_date: singleResponse.end_date,
                  });
                }
              });
            }
            break;
          case "bar":
            chartDetailsDataTemp =
              await DataSvc.getChartDataByGroupAggregations({
                ...requestParams,
                metric: JSON.parse(chartDataShown.metrics),
              });
            break;
          case "pie":
            chartDetailsDataTemp =
              await DataSvc.getChartDataByGroupAggregations({
                ...requestParams,
                metric: JSON.parse(chartDataShown.metrics),
              });
            break;
          case "table":
            chartDetailsDataTemp =
              await DataSvc.getChartDataByGroupAggregations({
                ...requestParams,
                metric: JSON.parse(chartDataShown.metrics),
                sort: "groups",
              });
            break;
          default:
            break;
        }
      };

      fetchChartsData().then(() => {
        setChartDetailsData(_.cloneDeep(chartDetailsDataTemp));
        setChartDetailsForLinesData(_.cloneDeep(chartDetailsForLinesDataTemp));
        setPrevYearChartDetailsForLinesData(
          _.cloneDeep(prevYearChartDetailsForLinesDataTemp)
        );
      });
    }

    // eslint-disable-next-line
  }, [chartDataShown, organizationId, usePrevYear]);

  // on Apply Filter Condition
  const onApplyFilterCondition = (
    conditionFormDataApply: FacilityFilterConditionData
  ) => {
    setConditionFormData(conditionFormDataApply);

    const municipality_filterValue: number[] = [];
    conditionFormDataApply.municipalities.forEach((item) => {
      item.muniList.forEach((itemMenu) => {
        municipality_filterValue.push(itemMenu.id);
      });
    });

    const category_filterValue = conditionFormDataApply.categories.map(
      (item) => {
        return item.id;
      }
    );
    const label_filterValue = conditionFormDataApply.labels.map((item) => {
      return item.id;
    });

    setChartDataShown({
      ...chartDataShown,
      municipality_filter:
        municipality_filterValue.length > 0
          ? municipality_filterValue.join(",")
          : null,
      category_filter:
        category_filterValue.length > 0 ? category_filterValue.join(",") : null,
      label_filter:
        label_filterValue.length > 0 ? label_filterValue.join(",") : null,
    });

    setShownModalFilterCondition(false);

    onUpdate(chartDataShown.id, {
      municipality_filter: flatten(
        conditionFormDataApply.municipalities.map((m) =>
          m.muniList.map((l) => l.id)
        )
      ).join(),
      category_filter: conditionFormDataApply.categories
        .map((c) => c.id)
        .join(),
      label_filter: conditionFormDataApply.labels.map((l) => l.id).join(),
    });
  };

  // on Click Clear Filter Condition
  const onClickClearFilterCondition = () => {
    setConditionFormData({
      ...defaultConditionFormData,
    });

    setChartDataShown({
      ...chartDataShown,
      municipality_filter: null,
      category_filter: null,
      label_filter: null,
    });

    onUpdate(chartDataShown.id, {
      municipality_filter: "",
      category_filter: "",
      label_filter: "",
    });
  };

  // has Condition Filter
  const hasConditionFilter = () => {
    let has = true;

    if (
      conditionFormData.healthIndex.length === 0 &&
      conditionFormData.categories.length === 0 &&
      conditionFormData.labels.length === 0
    ) {
      has = false;
    }

    let hasMunicipalities = false;
    conditionFormData.municipalities.forEach((item) => {
      if (item.muniList.length > 0) {
        hasMunicipalities = true;
      }
    });

    return has || hasMunicipalities;
  };

  // get Labels List
  const getLabelsList = (dataArray: any[]) => {
    const newDataArray: string[] = dataArray.map((item) => {
      return item.name;
    });

    return newDataArray.join(", ");
  };

  // onClickDateApply
  const onClickDateApply = (formData: AnalysisChartFilterData) => {
    setChartDataShown({
      ...chartDataShown,
      period_option: formData.periodDropdownValue,
      period_start: new Date(formData.fromDate).toISOString(),
      period_end: new Date(formData.toDate).toISOString(),
    });

    onUpdate(chartDataShown.id, {
      period_option: formData.periodDropdownValue,
      period_start: new Date(formData.fromDate).toISOString(),
      period_end: new Date(formData.toDate).toISOString(),
    });
  };

  const onApplyUsePrevYear = (usePrevYear: boolean) => {
    setUsePrevYear(usePrevYear);

    onUpdate(chartDataShown.id, {
      compare_to_prev: usePrevYear,
    });
  };

  return (
    <div className="collection-chart-area">
      {!!chartDataShown && (
        <>
          {!indivFacilityMode && (
            <div className="top-tools flex-grid">
              <div className="left-drop">
                <div className="two-circle">
                  <a
                    className={`icons up-icon ${
                      chartIndex === 0 ? "disabled" : ""
                    }`}
                    onClick={() => {
                      onClickMoveUp();
                    }}
                  >
                    &nbsp;
                  </a>
                  <a
                    className={`icons down-icon ${
                      chartIndex === totalLength - 1 ? "disabled" : ""
                    }`}
                    onClick={() => {
                      onClickMoveDown();
                    }}
                  >
                    &nbsp;
                  </a>
                </div>
              </div>
              <div className="right-btn">
                <DownloadCsvButton
                  chartData={chartDataShown}
                  chartDetailsData={chartDetailsData}
                  chartDetailsDataForLines={chartDetailsForLinesData}
                  prevYearChartDetailsDataForLines={
                    prevYearChartDetailsForLinesData
                  }
                  forWindows={true}
                />
                <DownloadCsvButton
                  chartData={chartDataShown}
                  chartDetailsData={chartDetailsData}
                  chartDetailsDataForLines={chartDetailsForLinesData}
                  prevYearChartDetailsDataForLines={
                    prevYearChartDetailsForLinesData
                  }
                  forWindows={false}
                />
                <a className="btn btn-border" onClick={onClickEditChart}>
                  <i className="icons icon-edit"></i>
                  {t("edit")}
                </a>
              </div>
            </div>
          )}
          <div className="chart-titles">
            {chartDataShown.name}
            {indivFacilityMode && (
              <div className="right-btn">
                {/* TODO:
              // Future feature to crate charts by copying current ones
              <a className='btn btn-border'
                onClick={onClickCopyChart}>
                <i className='icons icon-copy'></i>
                {t('copy')}
              </a>
              */}
                <a className="btn btn-border" onClick={onClickEditChart}>
                  <i className="icons icon-edit"></i>
                  {t("edit")}
                </a>
              </div>
            )}
          </div>
          <div className="date-bar flex-grid">
            {chartDataShown.template_name && (
              <div className="temlplate-txt">
                {t("template")}: {chartDataShown.template_name}
              </div>
            )}
            <DateRangeArea
              chartData={chartData}
              onClickApply={(formData: AnalysisChartFilterData) => {
                onClickDateApply(formData);
              }}
            />
          </div>
          {!indivFacilityMode && (
            <div
              className={`filter-wrap ${
                hasConditionFilter() ? "filter-after" : ""
              }`}
            >
              <div className="filter-some-block">
                <a
                  className="btn btn-filter"
                  onClick={() => {
                    setShownModalFilterCondition(true);
                  }}
                >
                  <i className="icons icon-filter"></i>
                  <span className="txt">
                    {!hasConditionFilter() ? t("filter") : t("filtered")}
                  </span>
                </a>
                <div className="after-info">
                  <div className="label-area">
                    {conditionFormData.healthIndex.length > 0 && (
                      <div className="filter-label-list flex">
                        <div className="left-txt">{t("health_index")} :</div>
                        <div className="labels">
                          {getLabelsList(conditionFormData.healthIndex)}
                        </div>
                      </div>
                    )}
                    {conditionFormData.municipalities.map((item, index) => (
                      <React.Fragment key={index}>
                        {item.muniList.length > 0 && (
                          <div className="filter-label-list flex" key={index}>
                            <div className="left-txt">{item.name} :</div>
                            <div className="labels">
                              {getLabelsList(item.muniList)}
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                    {conditionFormData.categories.length > 0 && (
                      <div className="filter-label-list flex">
                        <div className="left-txt">{t("categories")} :</div>
                        <div className="labels">
                          {getLabelsList(conditionFormData.categories)}
                        </div>
                      </div>
                    )}
                    {conditionFormData.labels.length > 0 && (
                      <div className="filter-label-list flex">
                        <div className="left-txt">{t("labels")} :</div>
                        <div className="labels">
                          {getLabelsList(conditionFormData.labels)}
                        </div>
                      </div>
                    )}
                  </div>
                  <a
                    href="#javascript"
                    className="btn btn-border"
                    onClick={(event) => {
                      onClickClearFilterCondition();
                      event.preventDefault();
                    }}
                  >
                    {t("clear_condition")}
                  </a>
                </div>
              </div>
            </div>
          )}

          <div className="chart-area">
            {chartDataShown.type === "bar" && (
              <BarChart chartDetailsData={chartDetailsData} />
            )}

            {chartDataShown.type === "line" && (
              <LineChart
                chartDetailsData={chartDetailsForLinesData}
                prevYearChartDetailsData={prevYearChartDetailsForLinesData}
                usePrevYear={usePrevYear}
                timeUnit={timeUnit}
                skipLabelTranslation={
                  !!(chartDataShown.grouping1 || chartDataShown.grouping2)
                }
                setTimeUnit={(unit: "day" | "week" | "month") => {
                  onUpdate(chartDataShown.id, {
                    time_unit: unit,
                  });
                  setTimeUnit(unit);
                }}
                setUsePrevYear={onApplyUsePrevYear}
              />
            )}

            {chartDataShown.type === "pie" && (
              <CircleChart chartDetailsData={chartDetailsData} />
            )}

            {chartDataShown.type === "table" && (
              <TableChart chartDetailsData={chartDetailsData} />
            )}
          </div>

          {/* TODO:
        <div className="points-area">
          <div className="title-line flex">
            <div className="left-txt">
              {t('points')}
            </div>
            <div className="line"></div>
          </div>
          <ul className="point-list">
            <li className='items'>
              <span className='points'></span>
              <p className="txt">
                {t('the_hot_springs_has_the_highest_number')}
              </p>
            </li>
            <li className='items'>
              <span className='points'></span>
              <p className="txt">
                {t('the_overall_number_of_views_increased')}
              </p>
            </li>
          </ul>
        </div>
        */}

          {shownModalFilterCondition && (
            <ModalFilterCondition
              organizationId={organizationId || ""}
              userId={me?.id}
              hideLodging={true}
              hideHealthIndex={true}
              hideProfBools={true}
              onClose={() => {
                setShownModalFilterCondition(false);
              }}
              conditionFormData={conditionFormData}
              onApply={(
                conditionFormDataApply: FacilityFilterConditionData
              ) => {
                onApplyFilterCondition(conditionFormDataApply);
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default withRouter(CollectionChartArea);
