import React from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import {
  INSTAGRAM_INTEGRATE_LOCATION_ID_LOCAL_STORAGE_KEY,
  INSTAGRAM_INTEGRATE_LOCATION_NAME_LOCAL_STORAGE_KEY,
  INSTAGRAM_REDIRECT_URL,
} from "../../../config";
import {
  checkMinimumInstagramPermissions,
  LocationData,
} from "../../../models/LocationData";

import "./styles.scss";

export interface IExternalIntegrationsProps {
  locationData: LocationData;
  onDisconnectInstagram: () => void;
}

export const ExternalIntegrations: React.FunctionComponent<
  IExternalIntegrationsProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`facilityDetailsPage.externalIntegrations.${key}`);

  const { locationData, onDisconnectInstagram } = props;

  const onIntegrateInstagram = () => {
    localStorage.setItem(
      INSTAGRAM_INTEGRATE_LOCATION_ID_LOCAL_STORAGE_KEY,
      `${locationData.id}`
    );
    localStorage.setItem(
      INSTAGRAM_INTEGRATE_LOCATION_NAME_LOCAL_STORAGE_KEY,
      `${locationData.name}`
    );
    ReactGA.event({
      category: "External Integration",
      action: "Integrate Instagram",
    });
    window.location.replace(INSTAGRAM_REDIRECT_URL);
  };

  const hasMinimumInstagramPermissions = checkMinimumInstagramPermissions(
    locationData.locations_instagram_integrations[0]
  );

  return (
    <div className="white-border-panel external-integrations padding32">
      <div className="row">
        <div className="col col-md-6 col-12">
          <div className="data-group">
            <div className="data-title">{t("instagram")}</div>
            {locationData.name !== "株式会社リーゴ" &&
              locationData.locations_instagram_integrations.length === 0 && (
                <p>
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: t("instagram_limited_release"),
                    }}
                  />
                </p>
              )}
            {locationData.locations_instagram_integrations?.length > 0 ? (
              <div className="data-row buttons">
                {hasMinimumInstagramPermissions ? (
                  <div className="btn btn-blue btn-blue--decorative">
                    <i className="icons icon-instagram"></i>@
                    {locationData.locations_instagram_integrations[0].username}
                    &nbsp;
                    {t("instagram_integrated")}
                  </div>
                ) : (
                  <div className="btn btn-red btn-red--decorative">
                    <i className="icons icon-instagram"></i>@
                    {locationData.locations_instagram_integrations[0].username}
                    &nbsp;
                    {t("instagram_insufficient_permissions")}
                  </div>
                )}
                <button
                  className="btn btn-border btn-border-red"
                  onClick={onDisconnectInstagram}
                >
                  {t("instagram_disconnect")}
                </button>
              </div>
            ) : (
              <div className="data-row">
                <button
                  className="btn btn-border"
                  onClick={onIntegrateInstagram}
                >
                  <i className="icons icon-instagram"></i>
                  {t("integrate_instagram")}
                </button>
              </div>
            )}
            {locationData.locations_instagram_integrations.length > 0 &&
              !hasMinimumInstagramPermissions && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "instagram_insufficient_permissions_instructions"
                    ),
                  }}
                />
              )}
            <small>{t("instagram_note")}</small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExternalIntegrations;
