import dayjs from "dayjs";

import {
  OrgFeatures,
  OrgFeatureUseLimitData,
} from "../models/FeatureUseLimitsData";

export enum FeatureUseStatus {
  CAN_USE = "can_use",
  LIMIT_REACHED = "limit_reached",
  EXPIRED = "expired",
  CANNOT_USE = "cannot_use",
}

// TODO: Generalize function for use with Org and Loc, or split into two functions
/**
 * Performs logic to determine whether a given target can use a given feature.
 * @param featureUseLimits The feature use limits data of the target
 * @param featureName The name of the feature
 * @returns The status of the feature use
 */
export const getFeatureUseStatus = (
  featureUseLimits: OrgFeatureUseLimitData[],
  featureName: OrgFeatures
): FeatureUseStatus => {
  const useLimit: OrgFeatureUseLimitData | undefined = featureUseLimits?.find(
    (item) => item.org_feature.name === featureName
  );

  if (useLimit === undefined) {
    return FeatureUseStatus.CANNOT_USE;
  }

  if (!useLimit.can_use) {
    return FeatureUseStatus.CANNOT_USE;
  }

  if (
    useLimit.org_feature.is_metered &&
    useLimit.times_triggered_in_cycle >= useLimit.org_feature.default_use_limit
  ) {
    return FeatureUseStatus.LIMIT_REACHED;
  }

  const expirationDate = dayjs(useLimit.specific_rule_expire_at);
  const now = dayjs();

  if (expirationDate.isBefore(now)) {
    return FeatureUseStatus.EXPIRED;
  }

  return FeatureUseStatus.CAN_USE;
};
