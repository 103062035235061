import React, { useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { GoogleInformation } from "../../../../models/GoogleInformation";
import { LocationData } from "../../../../models/LocationData";
import DataSvc from "../../../../services/dataSvc";
import { downloadCsv } from "../../../../utils/csvUtils";

import "./GetReviews.scss";

interface IGetReviewsProps extends RouteComponentProps<any> {
  onLoading(loadingText: string | void): void;
  onLoaded(): void;
  googleInformation?: GoogleInformation;
  locationData?: LocationData;
}
const GetReviews: React.FunctionComponent<IGetReviewsProps> = ({
  onLoading,
  onLoaded,
  googleInformation,
  locationData,
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`internaltoolsPage.getReviews.${key}`);
  const [locationName, setLocationName] = useState<string>("");
  const [placeName, setPlaceName] = useState<string>("");
  const [placeAddress, setPlaceAddress] = useState<string>("");
  const [skipNum, setSkipNum] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isRetryButtonEnable, setIsRetryButtonEnable] =
    useState<boolean>(false);

  const getReviews = async (isRetryButton: boolean, forWindows: boolean) => {
    onLoading(t("do_not_close_window"));

    if (!locationName && !locationData) {
      setErrorMessage(t("no_location_name"));
      return;
    }

    setIsRetryButtonEnable(false);
    let csvCount = 0;

    const fetchReviewSet = async (
      isRetryButton: boolean,
      nextSetToken?: string
    ): Promise<void> => {
      const querySkipNum = isRetryButton ? skipNum : null;

      if (locationData) {
        // User triggered get reviews
        ReactGA.event({
          category: "facility_details",
          action: "Download all reviews",
        });
      }

      // call API
      const {
        csvString,
        title,
        address,
        nextSetToken: newNextSetToken,
        skipNum: nextSkipNum,
      } = locationData
        ? await DataSvc.getReviewsForLocation({
            locationId: locationData.id,
            skipNum: querySkipNum ?? undefined,
            nextSetToken,
          })
        : await DataSvc.getReviews({
            accessToken: googleInformation?.accessToken,
            locationName: locationName,
            accountName: googleInformation?.accountName,
            skipNum: querySkipNum,
          });

      if (!csvString && skipNum === 1) {
        setPlaceName(title);
        setPlaceAddress(address);
        setSkipNum(nextSkipNum);
        setIsRetryButtonEnable(true);
        onLoaded();
        // break out of flow and let catch set the error message instead
        throw new Error(t("no_reviews"));
      }

      csvCount++;
      // split csv header and data
      const [header, ...content] = csvString.split("\n");
      // remove \" from header
      const translatedHeader = header
        .replace(/"/g, "")
        .split(",")
        .map((h: string) => t(`csvHeader.${h}`))
        .join(",");

      downloadCsv(
        [translatedHeader, ...content],
        `${locationData?.name ?? title}-reviews-part${csvCount}`,
        forWindows
      );

      if (newNextSetToken) {
        await fetchReviewSet(isRetryButton, newNextSetToken);
      } else {
        setPlaceName(title);
        setPlaceAddress(address);
        setErrorMessage(t("get_all_reviews"));
        setSkipNum(nextSkipNum);
        setIsRetryButtonEnable(true);
      }
    };

    try {
      await fetchReviewSet(isRetryButton);
      onLoaded();
    } catch (error) {
      setErrorMessage(String(error));
      onLoaded();
    }
  };

  return (
    <React.Fragment>
      <h1 className="main-title">
        {t(locationData ? "main_title_for_public" : "main_title")}
      </h1>
      {locationData ? (
        <div className="single-button-div">
          <section className="card">
            <p>
              <button
                className="internal-tools-button"
                onClick={() => getReviews(false, false)}
              >
                {t("execute")}
              </button>
            </p>
            <p>
              <button
                className="internal-tools-button"
                onClick={() => getReviews(false, true)}
              >
                {t("execute")}(Windows)
              </button>
            </p>
            {errorMessage && <h3 id="error">{errorMessage}</h3>}
          </section>
        </div>
      ) : (
        <>
          <div>
            <h2 className="sub-title">{t("input")}</h2>
            <section className="card">
              <p>{t("section_1_title_1")}</p>
              <p>{t("section_1_title_2")}</p>
              <p>{t("section_1_title_3")}</p>
              <input
                placeholder={t("location_name")}
                className="search-input"
                onChange={(e) => setLocationName(e.target.value)}
              />
              <p />
              <p>
                <button
                  onClick={() => getReviews(false, false)}
                  className="internal-tools-button"
                >
                  {t("execute")}
                </button>
              </p>
              <p>
                <button
                  onClick={() => getReviews(false, true)}
                  className="internal-tools-button"
                >
                  {t("execute")}(Windows)
                </button>
              </p>
              {errorMessage && <h3 id="error">{errorMessage}</h3>}
            </section>
          </div>
          <h2 className="sub-title">{t("facilities")}</h2>
          <section className="card">
            <p>{placeName}</p>
            <p>{placeAddress}</p>
            <p>{t("section_2_title_1")}</p>
            <p>
              <small>{t("section_2_title_2")}</small>
            </p>
            <p />
            <p>
              <button
                disabled={!isRetryButtonEnable}
                onClick={() => getReviews(true, false)}
                className="internal-tools-button"
              >
                {t("get_again")}
              </button>
            </p>
            <p>
              <button
                disabled={!isRetryButtonEnable}
                onClick={() => getReviews(true, true)}
                className="internal-tools-button"
              >
                {t("get_again")}(Windows)
              </button>
            </p>
          </section>
        </>
      )}
    </React.Fragment>
  );
};

export default withRouter(GetReviews);
