import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { parse } from "qs";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import DataSvc from "../services/dataSvc";

export const useLabels = (params: any, fetchWithoutBlock = false) =>
  useQuery(["labels", params], () => {
    if (!params.organization_id) {
      return [];
    } else {
      return DataSvc.getLabels(params, fetchWithoutBlock);
    }
  });
export const useCategories = (fetchWithoutBlock = false) =>
  useQuery(["categories"], () => DataSvc.getCategories(fetchWithoutBlock));
export const useMunicipalities = (organizationId?: string) =>
  useQuery(["municipalities", organizationId], () => {
    if (organizationId) {
      return DataSvc.getMunicipalities(organizationId);
    }
    return [];
  });
export const useLocationMunis = (
  userId?: number,
  organizationId?: string,
  fetchWithoutBlock = false
) =>
  useQuery(["muniLocations", userId, organizationId], () => {
    if (organizationId && organizationId !== "undefined") {
      return DataSvc.getMunicipalitiesOfLocations(
        organizationId,
        fetchWithoutBlock
      );
    }

    if (userId) {
      return DataSvc.getMunicipalitiesOfUser(userId, fetchWithoutBlock);
    }
    return [];
  });
export const useSearchQuery = () => {
  const { search } = useLocation();
  return useMemo(() => parse(search, { ignoreQueryPrefix: true }), [search]);
};
export const useMe = (noLoading?: boolean) => {
  const { logout } = useAuth0();
  return useQuery(["me"], () =>
    DataSvc.getAccountsMe(noLoading).catch((error) => {
      if (error.response?.status === 401 || error.response?.status === 403) {
        logout({ returnTo: window.location.origin });
      }
      throw error;
    })
  );
};
