import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { ReviewSummaryCommentsChart } from "./ReviewSummaryCommentsChart";
import { ReviewSummarySummarySection } from "./ReviewSummarySummarySection";
import { useDateTranslation } from "../../../hooks/dateTranslation";
import { useMe } from "../../../hooks/queries";
import { LocationData } from "../../../models/LocationData";
import { LocationReviewSummary } from "../../../models/LocationReviewSummary";
import DataSvc from "../../../services/dataSvc";
import { QRCodeDisplay } from "../QRCodeDisplay";

import "./styles.scss";

interface IReviewSummaryProps {
  locationData: LocationData;
}

export const ReviewSummary: React.FunctionComponent<IReviewSummaryProps> = ({
  locationData,
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string, params = {}) =>
    _t(`facilityDetailsPage.reviewSummary.${key}`, { ...params });

  const { translateDateRange } = useDateTranslation();

  const { data: me } = useMe();
  const organizationId =
    me?.type === "org_admin" ? me.organization_id : undefined;

  const { data } = useQuery<LocationReviewSummary>(
    ["location-latest-review-summary", locationData.id],
    () => DataSvc.getLatestLocationReviewSummary(locationData.id)
  );

  return (
    <div className="white-border-panel reviewSummary-module padding32">
      <div className="review-summary-container">
        <div className="top-bar">
          <div className="title">
            <span>{t("section_title")}</span>
            {data && (
              <span>
                {translateDateRange(
                  // Cast to Date because the data is serialized as a string
                  new Date(data.start_date),
                  new Date(data.end_date)
                )}
              </span>
            )}
            <div className="rights">{t("section_note")}</div>
          </div>
        </div>
        {!data && <div className="content">{t("no_content")}</div>}
        {data && (
          <div className="content">
            <div className="summary-container">
              <ReviewSummarySummarySection summary={data} />
              <NavLink
                to={`/location/${locationData.id}${
                  organizationId ? `/organization/${organizationId}` : ""
                }/review-summaries`}
                className="btn btn-blue"
              >
                {t("view_more")}
              </NavLink>
            </div>
            <ReviewSummaryCommentsChart data={data} />
          </div>
        )}
      </div>
      <div className="qr-code-container">
        <QRCodeDisplay
          content={`https://search.google.com/local/writereview?placeid=${locationData.gbp_place_id}`}
          downloadFileName={`${locationData.name}__GBP新規口コミ投稿URL_QR-CODE`}
        />
      </div>
    </div>
  );
};
