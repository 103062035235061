/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";

import { PAGE_NUMBER_OPTIONS } from "../../../config";
import { IUseLimitsFilterFormData } from "../../../containers/FunctionManagementPage";
import { InputBox } from "../../FormElement/InputBox";
import ToggleBtns from "../../ToggleBtns";
import { CurrentFilters } from "../CurrentFilters";

import "./styles.scss";

export interface IListTopBarProps {
  filterFormData: IUseLimitsFilterFormData;
  targetType: string;
  onChangeFilterFormData: (filterFormData: any) => void;
  onClickFilter: () => void;
  onClickClearFilterCondition: () => void;
}

export const ListTopBar: React.FunctionComponent<IListTopBarProps> = ({
  targetType,
  filterFormData,
  onClickFilter,
  onChangeFilterFormData,
  onClickClearFilterCondition,
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`functionManagementPage.functionList.${key}`);

  const [searchKeyword, setSearchKeyword] = useState<string>(
    filterFormData?.searchKeyword || ""
  );

  const getSearchInputBox = () => {
    return (
      <div className="search-module">
        <div className="top-txt">&nbsp;</div>
        <InputBox
          key={targetType}
          value={searchKeyword}
          placeholder={t(
            targetType === "org" ? "search_by_org_name" : "search_by_email"
          )}
          isSearch={true}
          classNameContainer={"search-input"}
          onChange={(value: string) => {
            setSearchKeyword(value);
          }}
          onKeyPress={(event: KeyboardEvent<HTMLImageElement>) => {
            if (event.key === "Enter") {
              onChangeFilterFormData({
                ...filterFormData,
                searchKeyword,
                pageIndex: 1,
              });
            }
          }}
        />
      </div>
    );
  };

  const filteredUseLimitsStatus = filterFormData.useLimitsStatus.filter(
    (item) => item.can_use !== undefined
  );

  return (
    <div className={`list-top-bar flex-grid`}>
      <div className="lefts">
        <div className="filter-wrap">
          <div className="filter-some-block">
            <a className="btn btn-filter" onClick={onClickFilter}>
              <i className="icons icon-filter"></i>
              <span className="txt">
                {t(filteredUseLimitsStatus.length > 0 ? "filtered" : "filter")}
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="rights flex">
        {getSearchInputBox()}
        <div className="choose-area">
          <div className="top-txt">{t("number_of_facilities_per_page")}</div>
          <ToggleBtns
            selectedValue={filterFormData.numberPerPage}
            options={PAGE_NUMBER_OPTIONS}
            onClickBtn={(option: number) => {
              onChangeFilterFormData({
                ...filterFormData,
                numberPerPage: option,
                pageIndex: 1,
              });
            }}
          />
        </div>
      </div>

      {filteredUseLimitsStatus.length > 0 && (
        <CurrentFilters
          conditionFormData={{ useLimitsStatus: filteredUseLimitsStatus }}
          onClickClearFilterCondition={onClickClearFilterCondition}
        />
      )}
    </div>
  );
};

export default ListTopBar;
