/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */

import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IUseLimitsFilterFormData } from "../../../containers/FunctionManagementPage";
import IfOrCondition from "../../FacilityListComponents/IfOrCondition";
import { ModalConfirm } from "../../ModalConfirm";
import "../../FacilityListComponents/ModalFilterCondition/styles.scss";
import "./styles.scss";

export interface IModalFilterConditionProps {
  filterFormData: IUseLimitsFilterFormData;
  onClose: () => void;
  onApply: (filterFormData: IUseLimitsFilterFormData) => void;
}

export const ModalFilterCondition: React.FunctionComponent<
  IModalFilterConditionProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`functionManagementPage.modalFunctionFilter.${key}`);

  const { onApply, onClose, filterFormData } = props;
  const [shownModalConfirm, setShownModalConfirm] = useState<boolean>(false); // false
  const [currentFilterConditions, setCurrentFilterConditions] = useState<any[]>(
    []
  );

  // if empty, it means no filter is selected
  // if not empty, it means filter is selected, and only one condition is selected
  const [selectFilterKey, setSelectFilterKey] = useState<string | null>(null);

  useEffect(() => {
    if (filterFormData.useLimitsStatus.length > 0) {
      setCurrentFilterConditions(filterFormData.useLimitsStatus);
      filterFormData.useLimitsStatus.forEach((item: any) => {
        if (item.can_use !== undefined) {
          setSelectFilterKey(item.name);
        }
      });
    }
  }, [filterFormData.useLimitsStatus]);

  const onClickApply = () => {
    onApply({
      ...filterFormData,
      useLimitsStatus: currentFilterConditions,
    });
  };

  const onClear = () => {
    const cloneCurrentFilterFormData = _.cloneDeep(currentFilterConditions);
    cloneCurrentFilterFormData.map((item: any) => {
      item.can_use = undefined;
      return item;
    });
    setSelectFilterKey(null);
    setCurrentFilterConditions(cloneCurrentFilterFormData);
  };

  const changeFilterCondition = (
    key: string,
    nextValue: boolean | undefined
  ) => {
    const cloneCurrentFilterFormData = _.cloneDeep(currentFilterConditions);
    cloneCurrentFilterFormData.map((item: any) => {
      if (item.name === key) {
        item.can_use = nextValue;
        setSelectFilterKey(nextValue === undefined ? null : key);
      }
      return item;
    });
    setCurrentFilterConditions(cloneCurrentFilterFormData);
  };

  return (
    <div className="function-management filter-panel">
      <div className="filter-mains">
        <a
          className="icons btn-close"
          onClick={() => {
            onClose();
          }}
        ></a>
        <div className="top-title flex-grid">
          <div className="titles">{t("filter_condition")}</div>
          <div className="rights">
            <a
              href="#javascript"
              className="btn btn-border"
              onClick={(event) => {
                setShownModalConfirm(true);
                event.preventDefault();
              }}
            >
              {t("clear")}
            </a>
          </div>
        </div>
        <div className="check-full-wrap">
          <div className="check-groups">
            <div className="blue-block-row flex">
              <div className="blue-block">{t("overview_function")}</div>
            </div>
            <div className="check-groups-horizontal">
              {currentFilterConditions.length > 0 &&
                currentFilterConditions.map((item: any) => {
                  return (
                    <IfOrCondition
                      key={item.id}
                      i18Key={"functionManagementPage.modalFilterCondition."}
                      isBlueTitle={false}
                      disabled={
                        selectFilterKey !== null &&
                        selectFilterKey !== item.name
                      }
                      item={{ key: item.name, shouldInclude: item.can_use }}
                      changeCheckbox={(key, nextValue) =>
                        changeFilterCondition(key, nextValue)
                      }
                    />
                  );
                })}
            </div>
          </div>
        </div>
        <div className="bottom-btns">
          <a
            className="btn btn-border"
            onClick={() => {
              onClose();
            }}
          >
            {t("cancel")}
          </a>
          <a
            className="btn btn-blue"
            onClick={(event) => {
              onClickApply();
              event.preventDefault();
            }}
          >
            {t("apply")}
          </a>
        </div>
      </div>

      {shownModalConfirm && (
        <ModalConfirm
          title={"please_confirm_to_reset"}
          cancelLabel={"no-go"}
          confirmLabel={"go"}
          onClose={() => {
            setShownModalConfirm(false);
          }}
          onConfirm={() => {
            onClear();
            setShownModalConfirm(false);
          }}
        />
      )}
    </div>
  );
};

export default ModalFilterCondition;
