import Encoding from "encoding-japanese";

// See documentation: https://www.npmjs.com/package/encoding-japanese
export const convertToJis = (csvString: string) => {
  const unicodeArray = Encoding.stringToCode(csvString)
  const sjisArray = Encoding.convert(unicodeArray, {
    to: 'SJIS',
    from: 'UNICODE',
    type: 'array'
  });
  return Encoding.urlEncode(sjisArray);
}

export const downloadCsv = (rows: string[], filename: string, forWindows: boolean) => {
  const lineBreakChar = forWindows ? "\r\n" : "\n";
  const csvContent = rows.join(lineBreakChar);

  let convertedCsvContent: string;
  if(forWindows) {
    convertedCsvContent = convertToJis(csvContent);
  } else {
    convertedCsvContent = encodeURI(csvContent);
  }

  const encodedUri = `data:text/csv;charset=${forWindows ? "Shift_JIS" : "utf-8"},\uFEFF${convertedCsvContent}`;

  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${filename}${forWindows ? '(Windows)' : ''}.csv`);
  document.body.appendChild(link);
  link.click();
}