import { useQuery } from "@tanstack/react-query";
import { TOptions } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

import DataSvc from "../../../services/dataSvc";
import "./style.scss";

interface IFeaturesSectionProps {
  type: "org" | "user";
  id: string | number;
  showTitle?: boolean;
}

export const FeaturesSection: React.FunctionComponent<
  IFeaturesSectionProps
> = ({ type, id, showTitle = false }) => {
  const { t: _t } = useTranslation();

  const t = (key: string, options?: TOptions) =>
    _t(`functionManagementPage.functionList.item_mapping.${key}`, options);

  const { data = [] } = useQuery(["useLimits", type, id], async () => {
    if (type === "user") {
      return DataSvc.getUseLimitsForUser(id);
    }
    return DataSvc.getUseLimitsForOrg(id);
  });

  if (data.length === 0) {
    return <></>;
  }

  return (
    <div>
      {showTitle && <div className="title">{t("features")}</div>}
      <div className="feature-section">
        {data.map((item: any) => {
          const {
            org_feature,
            user_feature,
            can_use_times,
            can_use,
            id: useLimitId,
            times_triggered_in_cycle,
            use_limit_cycle,
          } = item;

          // TODO: needs more info from DX team
          if (!can_use) {
            return null;
          }

          const featureName = org_feature?.name || user_feature?.name;
          const isMetered = org_feature?.is_metered || user_feature?.is_metered;
          return (
            <div
              className="feature-item-container"
              key={`${type}${useLimitId}`}
            >
              {isMetered
                ? t(`${featureName}`, {
                    total: can_use_times,
                    period: use_limit_cycle,
                    left: can_use_times - times_triggered_in_cycle,
                  })
                : t(`${featureName}`)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FeaturesSection;
