import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";
import {
  ArrangedUseLimitsData,
  ArrangedUseLimitData,
} from "../../../models/FeatureUseLimitsData";

export interface IIndividualSettingItemProps {
  arrangedUseLimits: ArrangedUseLimitsData;
  onClickEdit: () => void;
}

export const IndividualSettingItem: React.FunctionComponent<
  IIndividualSettingItemProps
> = ({ arrangedUseLimits, onClickEdit }) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`functionManagementPage.functionList.item.${key}`);
  const renderColumn = (key: number, text: string, showUnderline: boolean) => (
    <div className={`spacing ${showUnderline ? "feature-item" : ""}`} key={key}>
      <span className="blue-txt">{text}</span>
    </div>
  );

  const renderFeatureName = () => {
    if (
      arrangedUseLimits.use_limits &&
      arrangedUseLimits.use_limits.length > 0
    ) {
      return arrangedUseLimits.use_limits.map((useLimit, index) => {
        return renderColumn(
          useLimit.feature.id,
          _t(
            `functionManagementPage.modalFilterCondition.${useLimit.feature.name}.label`
          ),
          index < arrangedUseLimits.use_limits.length - 1
        );
      });
    }
    return <span className="blue-txt">-</span>;
  };

  const getUseLimitText = (useLimit: ArrangedUseLimitData) => {
    if (!useLimit.can_use) {
      return t("cannot_use");
    }

    if (
      useLimit?.specific_rule_expire_at &&
      dayjs().isAfter(dayjs(useLimit.specific_rule_expire_at))
    ) {
      return `${t("cannot_use")} (${dayjs(
        useLimit.specific_rule_expire_at
      ).format("YYYY-MM-DD")})`;
    } else {
      if (useLimit.feature.is_metered) {
        return `1 ${t(useLimit.use_limit_cycle)} ${useLimit.can_use_times} ${t(
          "times"
        )}`;
      }
      return t("can_use");
    }
  };

  const renderUseLimit = () => {
    return arrangedUseLimits.use_limits.map((useLimit, index) =>
      renderColumn(
        useLimit.feature.id,
        getUseLimitText(useLimit),
        index < arrangedUseLimits.use_limits.length - 1
      )
    );
  };

  return (
    <div className="row-td">
      <div className="col-td width10">
        <div className="spacing">
          <span className="blue-txt">
            {arrangedUseLimits.email || arrangedUseLimits.name || "-"}
          </span>
        </div>
      </div>
      <div className="col-td width10">{renderFeatureName()}</div>
      <div className="col-td width10">{renderUseLimit()}</div>
      <div className="col-td width10">
        <div className="spacing edit-button">
          <button className="btn btn-blue" onClick={onClickEdit}>
            {t("edit")}
          </button>
        </div>
      </div>
    </div>
  );
};
