import React from "react";
import { useTranslation } from "react-i18next";

import { LocationReviewSummary } from "../../../../models/LocationReviewSummary";

import "./styles.scss";

type IExtendedReviewSummaryCommentsSectionProps = {
  reviewSummary: LocationReviewSummary | undefined;
  type: "positive" | "negative";
};

export const CommentsSection = ({
  reviewSummary,
  type,
}: IExtendedReviewSummaryCommentsSectionProps) => {
  const { t: _t } = useTranslation();
  const t = (key: string, options?: any) =>
    _t(
      `facilityReviewSummariesPage.extendedReviewSummary.commentsSection.${key}`,
      options
    );
  return (
    <div className="section white-border-panel">
      <span className="section-title">{t(`${type}_comments`)}</span>
      {reviewSummary?.summary?.[
        type === "positive" ? "positiveComments" : "negativeComments"
      ]?.points?.length === 0 && <span>{t("no_comments")}</span>}
      <ol>
        {reviewSummary?.summary?.[
          type === "positive" ? "positiveComments" : "negativeComments"
        ]?.points?.map((point) => (
          <li key={point.title}>
            <div className="point">
              <div className="point-header">
                <span className="point-title">{point.title}</span>
                {/* Removed for now because the numbers are sometimes not accurate */}
                {/* <span className="point-count">
                  {t("comment_count", { count: point.count })}
                </span> */}
              </div>
              <span className="point-example">
                {t("comment_example", { example: point.example })}
              </span>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
};
