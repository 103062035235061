import { CategoryData } from './CategoryData';
import { LabelData } from './LabelData';
import { LocationsAccountsData } from './LocationsAccountsData';
import { LocationsOrganizationData } from './LocationsOrganizationsData';
import { OrganizationData } from './OrganizationData';
import { StatData } from './StatData';

export type LocationStatusType = "Registered" | "Confirming" | "Unregistered";

export enum InstagramPermissions {
  INSTAGRAM_BUSINESS_BASIC = 'instagram_business_basic',
  INSTAGRAM_BUSINESS_CONTENT_PUBLISHING = 'instagram_business_content_publish',
}

const requiredInstagramPermissions = [ InstagramPermissions.INSTAGRAM_BUSINESS_BASIC, InstagramPermissions.INSTAGRAM_BUSINESS_CONTENT_PUBLISHING ];
export interface InstagramIntegration {
  username: string;
  permissions: {
    permission: string;
  }[];
}
export interface LocationData {
  name: string;
  gbp_location_id: string;
  prefecture: string;
  city: string;
  profile: string;
  website_url: string;
  service_items: string;
  status: LocationStatusType;
  categories: CategoryData[];
  labels: LabelData[];
  organizations: OrganizationData[];
  locations_accounts: LocationsAccountsData[];
  stat: StatData;
  id: number;
  created_at: string;
  created_by: number;
  last_modified_at: string;
  last_modified_by: number;
  checked?: boolean;
  has_loc_admins: boolean;
  has_lodging: boolean;
  is_archived: boolean;
  is_archived_at: string;
  pickup_fields?: Record<string, boolean | null>;
  can_modify_service_list: boolean;
  can_have_food_menus: boolean;
  independent_use: boolean;
  gbp_place_id: string;
  locations_instagram_integrations: InstagramIntegration[];
}

// Used to correct display on the management page
export interface ImportLocationsLocationData extends LocationData {
  locations_organizations?: LocationsOrganizationData[];
  front_end_status?: LocationStatusType;
}

export const convertToImportLocationsLocationData = (locations : LocationData[], isOrgAdmin: boolean): ImportLocationsLocationData[] =>
  locations
    .map((location) => ({
      ...location,
      front_end_status: calculateImportStatus(location, isOrgAdmin),
    })).sort((a, b) => sortByImportStatus(a.front_end_status, b.front_end_status));


const calculateImportStatus = (location : ImportLocationsLocationData, isOrgUser : boolean): LocationStatusType => {
  if(location.locations_accounts.length === 0 || !location.locations_accounts[0].verified) {
    return 'Unregistered';
  }

  if(
    location.status === 'Confirming' ||
    // Case: waiting for liigo admin to create location_organization link through admin screen
    (isOrgUser && location.locations_organizations?.length === 0)
  ) {
    return 'Confirming';
  }

  return 'Registered';
}

const sortByImportStatus = (a: LocationStatusType, b: LocationStatusType) => {
  if(a === b) { return 0}

  if(
    a === 'Registered' || 
    (a === 'Confirming' && b !== 'Registered')) {
    return 1;
  }

  return -1;
}

export const checkMinimumInstagramPermissions = (integration: InstagramIntegration) => {
  if(!integration?.permissions) { return false; }
  return requiredInstagramPermissions.every((permission) => integration.permissions.find((p) => p.permission === permission));
}