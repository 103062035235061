import React from "react";
import "./styles.scss";

export interface ICheckboxProps {
  checked: boolean;
  label: string;
  isSelectAll?: boolean;
  disabled?: boolean;
  subLabel?: string;
  errorLabel?: string;
  id: string;
  onChange: (checked: boolean) => void;
}

export const Checkbox: React.FunctionComponent<ICheckboxProps> = (props) => {
  const {
    checked,
    label,
    isSelectAll,
    subLabel,
    id,
    disabled = false,
    errorLabel,
  } = props;
  return (
    <div
      className={`checkbox-wrap ${label !== "" ? "have-txt" : ""} ${
        isSelectAll ? "checkbox-select-all" : ""
      }`}
    >
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        id={id}
        onChange={(evt) => props.onChange(evt.target.checked)}
      />
      <label htmlFor={id}>
        <span className={`main-label ${disabled ? "disabled" : ""}`}>
          {label}
        </span>
        {!!subLabel && <span className="little">{subLabel}</span>}
      </label>
      {!!errorLabel && <span className="error-label">{errorLabel}</span>}
    </div>
  );
};

export default Checkbox;
