/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";
import { changeLanguage } from "../../i18n";

export interface IModalPopupMenuProps {
  onClose: () => void;
  setModalPrivacyPolicyAndTermsServiceType: (value: string) => void;
  setShownModalConfirmLogout: (value: boolean) => void;
}

export const ModalPopupMenu: React.FunctionComponent<IModalPopupMenuProps> = (
  props
) => {
  const { t: _t } = useTranslation();
  const leftMenuT = (key: string) => _t(`leftMenu.${key}`);
  const headerT = (key: string) => _t(`header.${key}`);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onClose } = props;
  const {
    setModalPrivacyPolicyAndTermsServiceType,
    setShownModalConfirmLogout,
  } = props;

  return (
    <React.Fragment>
      <div className="modal modal-default modal-popup-menu">
        <div className="modal-mains">
          <a
            className="btn-close"
            onClick={() => {
              onClose();
            }}
          ></a>
          <div className="top-title">{leftMenuT("menu")}</div>
          <div className="content">
            <ul>
              <li>
                <a className="link" onClick={changeLanguage}>
                  {headerT("other_language")}
                </a>
              </li>
              <li>
                <a
                  className="link"
                  onClick={() => {
                    setModalPrivacyPolicyAndTermsServiceType("termsService");
                  }}
                >
                  {headerT("terms_of_service")}
                </a>
              </li>
              <li>
                <a
                  className="link"
                  onClick={() => {
                    setModalPrivacyPolicyAndTermsServiceType("privacyPolicy");
                  }}
                >
                  {headerT("privacy_policy")}
                </a>
              </li>
              <li>
                <a
                  className="link"
                  onClick={() => setShownModalConfirmLogout(true)}
                >
                  {headerT("logout")}
                </a>
              </li>
            </ul>
          </div>
          <div className="bottom-btns">
            <a
              className="btn btn-blue"
              onClick={() => {
                onClose();
              }}
            >
              {headerT("close")}
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ModalPopupMenu;
