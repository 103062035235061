enum UseLimitCycleType {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
}

export interface FeatureData {
  id: number;
  name: string;
  default_can_use: boolean;
  default_use_limit: number;
  use_limit_cycle: UseLimitCycleType;
  created_at: string;
  created_by: number;
  last_modified_at: string;
  last_modified_by: number;
  is_metered: boolean;
}

export interface ArrangedUseLimitData {
  feature: FeatureData;

  can_use: boolean;
  can_use_times?: number;
  use_limit_cycle: UseLimitCycleType;
  specific_rule_expire_at: string;
  times_triggered_in_cycle: number;
}

export interface ArrangedUseLimitsData {
  use_limits: ArrangedUseLimitData[];

  target: string; // org or user
  target_id: number;

  name?: string;
  email?: string;
}


export interface OrgFeatureUseLimitData {
  can_use: boolean;
  can_use_times: number;
  feature_id: string;
  id: string;
  specific_rule_expire_at: string;
  target_org_id: string;
  times_triggered_in_cycle: number;
  org_feature: OrgFeatureData;
}

export interface OrgFeatureData {
  created_at: string;
  created_by: string;
  default_can_use: boolean;
  default_use_limit: number;
  id: string;
  is_metered: boolean;
  last_modified_at?: string;
  last_modified_by: string;
  name: OrgFeatures;
  use_limit_cycle: string;
}

export enum OrgFeatures {
  CAN_USE_MASS_POST = "can_use_mass_post",
  RECEIVE_ORG_REPORTS = "receive_org_reports",
  USE_LOCAL_POST = "use_local_post",
}
