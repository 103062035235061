import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter, useHistory } from "react-router-dom";

import { SuggestionsFilterBar } from "../../components/SuggestionsListComponents/SuggestionsFilterBar";
import { SuggestionsTable } from "../../components/SuggestionsListComponents/SuggestionsTable";
import { useMe } from "../../hooks/queries";
import { AccountType } from "../../models/AccountData";
import {
  OrgRecommendationData,
  OrgRecommendationHistoryData,
} from "../../models/OrgRecommendationData";
import DataSvc from "../../services/dataSvc";
import { compareDate } from "../../utils/common";
import { canAccessOrganization } from "../../utils/userValidation";
import SendMessageScreens from "../SendMessageScreens";

import "./styles.scss";

type ISuggestionsListPageProps = RouteComponentProps<any>;

const SuggestionsListPage: React.FunctionComponent<
  ISuggestionsListPageProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`suggestionsListPage.${key}`);

  const history = useHistory();
  const [organizationId, setOrganizationId] = useState<string>("");
  const [generatedDate, setGeneratedDate] = useState<string>("");
  const [lastGeneratedDate, setLastGeneratedDate] = useState<string>("");
  const [previousSuggestions] = useState<string>(
    _t(`common.dropdown.${"Previous Suggestions"}`)
  );

  const [showSendMessageScreens, setShowSendMessageScreens] = useState(false);
  const [activeRecommendation, setActiveRecommendation] = useState<
    OrgRecommendationData | undefined
  >(undefined);
  const [showSendCustomMessageScreens, setShowSendCustomMessageScreens] =
    useState(false);

  const [recommendationsHistoryData, setRecommendationsHistoryData] =
    useState<OrgRecommendationHistoryData>();

  const { data: me } = useMe();

  const { data: recommendationsData } = useQuery(
    ["recommendations", organizationId, generatedDate],
    () => {
      if (organizationId && generatedDate) {
        return DataSvc.getRecommendations(
          {
            organization_id: organizationId,
            generated_date: generatedDate,
          },
          false
        );
      }
      return [];
    }
  );

  const { data: recommendationsHistory } = useQuery(
    ["recommendationsHistory", organizationId],
    () => {
      if (organizationId) {
        return DataSvc.getRecommendationsHistory(organizationId, 10, false);
      }
      return [];
    }
  );

  useEffect(() => {
    setOrganizationId(props.match.params.organizationId || "");

    setGeneratedDate(props.match.params.generatedDate || "");
    // eslint-disable-next-line
  }, [props.match.params.organizationId]);

  useEffect(() => {
    if (recommendationsHistory?.histories?.length > 0) {
      setRecommendationsHistoryData(recommendationsHistory);

      const last_generated_date = getLastGeneratedDataFromHistory(
        recommendationsHistory
      );
      setLastGeneratedDate(last_generated_date);
      if (!generatedDate) {
        setGeneratedDate(last_generated_date);
      }
    }
    // eslint-disable-next-line
  }, [recommendationsHistory]);

  // get Last Generated Data From History
  const getLastGeneratedDataFromHistory = (
    recommendationsHistory: OrgRecommendationHistoryData
  ) => {
    let generated_date = "";

    recommendationsHistory.histories.forEach((item) => {
      if (generated_date === "") {
        generated_date = item.generated_date;
      } else {
        if (compareDate(generated_date, item.generated_date)) {
          generated_date = item.generated_date;
        }
      }
    });

    return generated_date;
  };

  // on Change Previous Suggestions Date
  const onChangePreviousSuggestionsDate = (
    newPreviousSuggestionsDate: string
  ) => {
    history.push(
      `/recommendations/organization/${organizationId}/${newPreviousSuggestionsDate}`
    );
  };

  useEffect(() => {
    if (!me) {
      return;
    }

    if (me?.type === AccountType.LOC_ADMIN) {
      // Redirect to home
      history.push("");
      return;
    }

    if (canAccessOrganization(props.match.params.organizationId, me)) {
      setOrganizationId(props.match.params.organizationId);
    } else {
      history.push(`/recommendations/organization/${me.organization.id}`);
    }
    // eslint-disable-next-line
  }, [props.match.params.organizationId, me]);

  const onOpenSendMessageScreens = (recommendation: OrgRecommendationData) => {
    setShowSendMessageScreens(true);
    setActiveRecommendation(recommendation);
  };

  const setShowMessageScreen = (shouldActivate: boolean) => {
    setShowSendMessageScreens(shouldActivate);
    if (shouldActivate === false) {
      setActiveRecommendation(undefined);
    }
  };

  const onOpenCustomSendMessageScreens = () => {
    setShowSendCustomMessageScreens(true);
  };

  return (
    <>
      <div className="right-content suggestions-list">
        <div className="top-title-bar flex-grid">
          <div className="left-title">{t("suggestions")}</div>
          <div className="rights">
            <button
              onClick={onOpenCustomSendMessageScreens}
              className="btn btn-blue"
            >
              {t("custom_email")}
            </button>
          </div>
        </div>

        <SuggestionsFilterBar
          generatedDate={generatedDate}
          lastGeneratedDate={lastGeneratedDate}
          previousSuggestions={previousSuggestions}
          recommendationsHistoryData={recommendationsHistoryData}
          onChangePreviousSuggestionsDate={(
            newPreviousSuggestionsDate: string
          ) => {
            onChangePreviousSuggestionsDate(newPreviousSuggestionsDate);
          }}
        />

        <SuggestionsTable
          title={"all"}
          main_section={true}
          dataList={recommendationsData || []}
          openRecommendation={onOpenSendMessageScreens}
        />

        <SuggestionsTable
          title={"by_category"}
          main_section={false}
          dataList={recommendationsData || []}
          openRecommendation={onOpenSendMessageScreens}
        />
      </div>
      {showSendMessageScreens &&
        activeRecommendation?.relevant_locations &&
        activeRecommendation.relevant_locations.length > 0 && (
          <SendMessageScreens
            locationData={activeRecommendation.relevant_locations}
            orgRecommendationId={Number(activeRecommendation.id)}
            setShowSendMessageScreens={setShowMessageScreen}
          />
        )}
      {showSendCustomMessageScreens && (
        <SendMessageScreens
          organizationId={organizationId}
          locationData={[]}
          isCustomFilterMode={true}
          setShowSendMessageScreens={setShowSendCustomMessageScreens}
        />
      )}
    </>
  );
};

export default withRouter(SuggestionsListPage);
