import { useTranslation } from "react-i18next";

/**
 * Hook to translate dates in a consistent way using react-i18next
 * Can be extended at a later date with options for hiding the year etc.
 * @returns An object containing two functions: translateDate and translateDateRange
 */
export const useDateTranslation = () => {
  const { t } = useTranslation();

  /**
   * Translate a date using react-i18next
   * @returns The translated date string or "INVALID_DATE" if the date is invalid
   */
  const translateDate = (date: Date) => {
    if (!date || Object.prototype.toString.call(date) !== "[object Date]") {
      return "INVALID_DATE";
    }
    return t("common.date", {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    });
  };

  const translateDateRange = (startDate: Date, endDate: Date) => {
    if (
      !startDate ||
      !endDate ||
      Object.prototype.toString.call(startDate) !== "[object Date]" ||
      Object.prototype.toString.call(endDate) !== "[object Date]"
    ) {
      return "INVALID_DATE";
    }
    return t("common.date_range", {
      startYear: startDate.getFullYear(),
      startMonth: startDate.getMonth() + 1,
      startDay: startDate.getDate(),
      endYear: endDate.getFullYear(),
      endMonth: endDate.getMonth() + 1,
      endDay: endDate.getDate(),
    });
  };

  return { translateDate, translateDateRange };
};
