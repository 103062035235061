import React from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { useMe } from "../../../hooks/queries";
import { AccountType } from "../../../models/AccountData";
import { FacilityFilterConditionData } from "../../../models/FacilityFilterConditionData";
import { LabelData } from "../../../models/LabelData";
import { LocationData } from "../../../models/LocationData";
import {
  formatNumWithNullNote,
  formatPercWithNullNote,
} from "../../../utils/formatValidation";
import "./styles.scss";
import { getMainCategory } from "../../../utils/getMainCategory";
import Pagination from "../../Pagination";
import ScoreItem from "../../ScoreItem";
import TipsIcon from "../../TipsIcon";
import { FacilityListHeader } from "../FacilityListHeader";
import LabelsList from "../LabelsList";
import ListTopBar from "../ListTopBar";

export interface IFacilityListProps {
  dataList: LocationData[];
  totalCount: number;
  conditionFormData: FacilityFilterConditionData;
  filterFormData: any;
  organizationId: string;
  onClickFilter: () => void;
  onClickClearFilterCondition: () => void;
  onDeleteLabel: (
    indexFacility: number,
    indexLabel: number,
    hasConfirm: boolean
  ) => void;
  onAddLabel: (indexFacility: number, label: LabelData) => void;
  onChangeFilterFormData: (filterFormData: any) => void;
}

export const FacilityList: React.FunctionComponent<IFacilityListProps> = (
  props
) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`facilityListPage.facilityList.${key}`);

  const { data: me } = useMe();

  const isOrgUser = () => {
    return me?.type !== AccountType.LOC_ADMIN;
  };

  const {
    dataList,
    totalCount,
    conditionFormData,
    filterFormData,
    organizationId,
    onClickFilter,
    onClickClearFilterCondition,
    onDeleteLabel,
    onAddLabel,
    onChangeFilterFormData,
  } = props;

  // get Tips Text
  const getTips = (score: string, item: LocationData) => {
    if (!score) {
      return "";
    }
    if (me) {
      if (me.type === AccountType.ORG_ADMIN || me.type === AccountType.ADMIN) {
        return t(
          `tipsTexts${
            item.has_lodging ? "OrgAdminHotel" : "OrgAdmin"
          }.${score.toUpperCase()}`
        );
      }
      if (me.type === AccountType.LOC_ADMIN) {
        return t(
          `tipsTexts${
            item.has_lodging ? "LocAdminHotel" : "LocAdmin"
          }.${score.toUpperCase()}`
        );
      }
    }
    return "";
  };

  return (
    <>
      <div className="white-border-panel">
        <ListTopBar
          conditionFormData={conditionFormData}
          filterFormData={filterFormData}
          onClickFilter={() => {
            onClickFilter();
          }}
          onClickClearFilterCondition={() => {
            onClickClearFilterCondition();
          }}
          onChangeFilterFormData={(filterFormData: any) => {
            onChangeFilterFormData(filterFormData);
          }}
        />

        <div className="table-data table-data-grid table-data-grid table-data-grid-pc-only">
          <FacilityListHeader
            filterFormData={filterFormData}
            onChangeFilterFormData={onChangeFilterFormData}
          />
          {(!dataList || dataList.length === 0) && (
            <div className="row-td text-center sub-txt">{t("no_location")}</div>
          )}
          {dataList
            .slice(0, filterFormData.numberPerPage)
            .map((item: LocationData, indexFacility: number) => (
              <div className="row-td" key={indexFacility}>
                <div className="col-td facility-title">
                  <div className="spacing">
                    <NavLink
                      to={`/location/${item.id}/${
                        organizationId ? `organization/${organizationId}` : ""
                      }`}
                      className="blue-link"
                    >
                      {item.name}
                    </NavLink>
                    <br />
                    <a
                      href={`https://business.google.com/n/${item.gbp_location_id.replace(
                        "locations/",
                        ""
                      )}/profile`}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-blue"
                      onClick={() => {
                        ReactGA.event({
                          category: "GBP Link",
                          action: "Click to GBP",
                          label: "main",
                        });
                      }}
                    >
                      {t("to_facilitys_gbp_profile")}
                    </a>
                  </div>
                </div>
                <div className="col-td score center">
                  <div className="spacing">
                    <ScoreItem score={item.stat.score} />

                    <TipsIcon tipsLabel={getTips(item.stat.score, item)} />
                  </div>
                </div>
                <div className="col-td profile-views text-right">
                  <div className="spacing">
                    <div className="top-num">
                      <span className="sp-note">{t("profile_views")}: </span>
                      {formatNumWithNullNote(
                        item.stat.profile_views,
                        item.is_archived
                      )}
                    </div>
                    {formatPercWithNullNote(
                      item.stat.profile_views_change_percentage,
                      item.is_archived
                    )}
                  </div>
                </div>
                <div className="col-td direction-search-count text-right">
                  <div className="spacing">
                    <div className="top-num">
                      <span className="sp-note">
                        {t("direction_search_count")}:
                      </span>
                      {formatNumWithNullNote(
                        item.stat.direction_search_count,
                        item.is_archived
                      )}
                    </div>
                    {formatPercWithNullNote(
                      item.stat.direction_search_count_change_percentage,
                      item.is_archived
                    )}
                  </div>
                </div>
                <div className="col-td muni font14 center">
                  <div className="spacing">
                    <div className="txt">
                      {item.prefecture} {item.city}
                    </div>
                  </div>
                </div>
                <div className="col-td category font14 center">
                  <div className="spacing">{getMainCategory(item)}</div>
                </div>
                {isOrgUser() && (
                  <div className="col-td label">
                    <div className="spacing">
                      <LabelsList
                        organizationId={organizationId}
                        dataList={item.labels}
                        onDeleteLabel={(
                          indexLabel: number,
                          hasConfirm: boolean
                        ) => {
                          onDeleteLabel(indexFacility, indexLabel, hasConfirm);
                        }}
                        onAddLabel={(label: LabelData) => {
                          onAddLabel(indexFacility, label);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
      <small className="footnote">
        {t("may_require_gbp_login")} <br />
        {_t("data.data_possibly_outdated")}
      </small>

      <Pagination
        filterFormData={filterFormData}
        totalRowsNumber={totalCount}
        onChangeFilterFormData={(filterFormData: any) => {
          onChangeFilterFormData(filterFormData);
        }}
      />
    </>
  );
};

export default FacilityList;
