import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Redirect,
  RouteComponentProps,
  useHistory,
  withRouter,
} from "react-router-dom";
import { toast } from "react-toastify";

import {
  INSTAGRAM_INTEGRATE_LOCATION_ID_LOCAL_STORAGE_KEY,
  INSTAGRAM_INTEGRATE_LOCATION_NAME_LOCAL_STORAGE_KEY,
} from "../../config";
import { useMe } from "../../hooks/queries";
import DataSvc from "../../services/dataSvc";

import "./styles.scss";

type IIntegrateInstagramPageProps = RouteComponentProps;

const IntegrateInstagramPage: React.FunctionComponent<
  IIntegrateInstagramPageProps
> = () => {
  const { t: _t } = useTranslation();

  const t = useCallback(
    (key: string) => _t(`integrateInstagramPage.${key}`),
    [_t]
  );

  const { data: me } = useMe();
  const history = useHistory();

  const locationId = localStorage.getItem(
    INSTAGRAM_INTEGRATE_LOCATION_ID_LOCAL_STORAGE_KEY
  );
  const locationName = localStorage.getItem(
    INSTAGRAM_INTEGRATE_LOCATION_NAME_LOCAL_STORAGE_KEY
  );

  const authorizationCode = new URLSearchParams(window.location.search).get(
    "code"
  );

  // Needed as changes of one of the dependencies causes double sending
  const callSent = useRef(false);

  useEffect(() => {
    if (
      locationId &&
      locationName &&
      history &&
      authorizationCode &&
      !!me &&
      t &&
      callSent.current === false
    ) {
      callSent.current = true;
      DataSvc.authorizeInstagram({
        location_id: locationId,
        authorization_code: authorizationCode,
      })
        .then(() => {
          localStorage.removeItem(
            INSTAGRAM_INTEGRATE_LOCATION_ID_LOCAL_STORAGE_KEY
          );
          localStorage.removeItem(
            INSTAGRAM_INTEGRATE_LOCATION_NAME_LOCAL_STORAGE_KEY
          );
          toast.success(t("instagram_integration_success"));
          history.push(`/location/${locationId}`);
        })
        .catch((err) => {
          toast.error(t("instagram_integration_failed_please_try_again"));
          toast.error(t(err.message));
          history.push(`/location/${locationId}`);
        });
    }
  }, [locationId, locationName, history, authorizationCode, me, t]);

  if (!locationId) {
    toast.error(t("cannot_find_location_id"));
    return <Redirect to="/locations" />;
  }

  if (!authorizationCode) {
    toast.error(t("instagram_integration_failed_please_try_again"));
    return <Redirect to={`/location/${locationId}`} />;
  }

  return (
    <div className="right-content instagram-integrate">
      <div className="top-title-bar flex-grid">
        <div className="left-title">{t("instagram_integration")}</div>
      </div>
      <p>
        <strong>
          {locationName}：{t("integrating_instagram")}
        </strong>
      </p>
    </div>
  );
};

export default withRouter(IntegrateInstagramPage);
