import { useQuery } from "@tanstack/react-query";
import React from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";

import { ExtendedReviewSummary } from "../../components/FacilityReviewSummariesComponents/ExtendedReviewSummary";
import { ReviewSummarySelector } from "../../components/FacilityReviewSummariesComponents/ReviewSummarySelector";
import { useDateTranslation } from "../../hooks/dateTranslation";
import { useMe } from "../../hooks/queries";
import {
  LocationReviewSummary,
  LocationReviewSummaryHistory,
} from "../../models/LocationReviewSummary";
import DataSvc from "../../services/dataSvc";

import "./styles.scss";

interface MatchParams {
  locationId?: string;
  organizationId?: string;
}

type IFacilityReviewSummariesPageProps = RouteComponentProps<MatchParams>;

const FacilityReviewSummariesPage: React.FunctionComponent<
  IFacilityReviewSummariesPageProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string, options?: any) =>
    _t(`facilityReviewSummariesPage.${key}`, options);
  const { translateDateRange } = useDateTranslation();

  const { data: me } = useMe();
  const locationId = props.match?.params?.locationId ?? "";

  const { data: locationData } = useQuery(
    ["location", locationId],
    async () => {
      return await DataSvc.getLocation(locationId);
    }
  );

  const { data: history, isFetched: isFetchedHistory } = useQuery<
    LocationReviewSummaryHistory[]
  >(["location-review-summary-history", locationId], () =>
    DataSvc.getLocationReviewSummaryHistory(Number(locationId))
  );

  const [reviewSummaryId, setReviewSummaryId] = React.useState<
    bigint | undefined
  >(undefined);

  React.useEffect(() => {
    setReviewSummaryId(history?.[0]?.id);
  }, [history]);

  const { data: reviewSummary, isFetched: isFetchedReviewSummary } =
    useQuery<LocationReviewSummary>(
      ["location-review-summary", locationId, reviewSummaryId],
      () =>
        DataSvc.getLocationReviewSummary(
          Number(locationId),
          Number(reviewSummaryId)
        ),
      { enabled: isFetchedHistory }
    );

  const gbpLocIdNum =
    locationData?.gbp_location_id.replace("locations/", "") || undefined;

  return (
    <div className="right-content facility-review-summaries">
      <div className="back-top-bar flex-grid">
        <NavLink
          to={`/location/${locationId}${
            me?.organization_id ? `/organization/${me?.organization_id}` : ""
          }`}
          className="back-link"
        >
          <i className="icons icon-back"></i>
          {t("back_to_facility_details")}
        </NavLink>
        <div className="right-btns">
          {!!gbpLocIdNum && (
            <div className="to-gbp-button">
              <a
                href={`https://business.google.com/n/${gbpLocIdNum}/profile`}
                target="_blank"
                rel="noreferrer"
                className="btn btn-blue"
                onClick={() => {
                  ReactGA.event({
                    category: "GBP Link",
                    action: "Click to GBP",
                    label: "main",
                  });
                }}
              >
                {t("to_facilitys_gbp_profile")}
              </a>
              <small className="footnote">{t("may_require_gbp_login")}</small>
            </div>
          )}
        </div>
      </div>
      {!!locationData && (
        <div className="big-bold-title">{locationData.name}</div>
      )}
      <div className="sub-title">
        <span>{t("review_summaries")}</span>
        {reviewSummary && (
          <span>
            {translateDateRange(
              // Cast to Date because the data is serialized as a string
              new Date(reviewSummary?.start_date),
              new Date(reviewSummary?.end_date)
            )}
          </span>
        )}
      </div>
      {!!history && history.length > 0 && (
        <ReviewSummarySelector
          history={history}
          selectedId={reviewSummaryId}
          onSelectId={setReviewSummaryId}
        />
      )}
      {!!reviewSummary && (
        <ExtendedReviewSummary reviewSummary={reviewSummary} />
      )}
      {!reviewSummary && isFetchedReviewSummary && (
        <div>{t("no_review_summaries")}</div>
      )}
    </div>
  );
};

export default withRouter(FacilityReviewSummariesPage);
