import React from "react";
import { useTranslation } from "react-i18next";

import { CreateLocalPostState, LocalPostTopicType } from "../utils";

export interface IGenerateAiTextButtonProps {
  onClick: () => void;
  canGenerateAiText?: boolean;
  localPostState: CreateLocalPostState;
}

export const GenerateAiTextButton: React.FunctionComponent<
  IGenerateAiTextButtonProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(
      `analysisCollectionsPage.modalCreateLocalPost.createLocalPostForm.${key}`
    );
  const { onClick, canGenerateAiText, localPostState } = props;

  const enabled =
    localPostState.topicType === LocalPostTopicType.STANDARD
      ? localPostState.media.length > 0
      : localPostState.media.length > 0 &&
        localPostState.event.title &&
        localPostState.event.schedule.startDate &&
        localPostState.event.schedule.endDate;

  if (!canGenerateAiText) {
    return null;
  }

  return (
    <button
      className={`btn btn-blue ${enabled ? "" : "disabled"}`}
      onClick={onClick}
    >
      {t("generate_text_with_ai_from_photo")}
    </button>
  );
};
