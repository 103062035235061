import { LocationData } from "./LocationData";
import { OrganizationData } from "./OrganizationData";

export enum AccountType {
  ADMIN = "admin",
  ORG_ADMIN = "org_admin",
  LOC_ADMIN = "loc_admin",
}

export interface AccountData {
  id: number;
  uuid: string;
  name: string;
  type: AccountType;
  role: string;
  refresh_token: string;
  email: string;
  activated: boolean;
  organization: OrganizationData;
  accounts: string[];
  locations: LocationData[];
  locations_accounts: any[];
  created_at: string;
  created_by: number;
  last_modified_at: string;
  last_modified_by: number;
  last_login_at: string;
  organization_id: string | null;
  linked_to_google_account: boolean | null;
}

export interface AccountInformationData {
  familyName: string;
  firstName: string;
  email: string;
  role: string;
}
