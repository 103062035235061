import _ from "lodash";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import { HEALTH_INDEX_OPTIONS, PROFILE_BOOLEAN_OPTIONS } from "../../../config";
import {
  useLabels,
  useCategories,
  useLocationMunis,
} from "../../../hooks/queries";
import { CategoryData } from "../../../models/CategoryData";
import {
  FacilityFilterConditionData,
  createDefaultConditionFormData,
} from "../../../models/FacilityFilterConditionData";
import { HealthIndexData } from "../../../models/HealthIndexData";
import { LabelData } from "../../../models/LabelData";
import {
  MunicipalityGroupData,
  MunicipalityData,
} from "../../../models/MunicipalityData";
import { ProfBooleanData } from "../../../models/ProfBooleanData";
import { modalViewGAEvent } from "../../../utils/ga";
import { ModalConfirm } from "../../ModalConfirm";
import IfOrCondition from "../IfOrCondition";
import ItemCondition from "../ItemCondition";

import "./styles.scss";

export interface IModalFilterConditionProps {
  organizationId: string;
  userId?: number;
  hideLodging?: boolean;
  hideHealthIndex?: boolean;
  hideProfBools?: boolean;
  conditionFormData: FacilityFilterConditionData;
  onClose: () => void;
  onApply: (conditionFormData: FacilityFilterConditionData) => void;
}

export interface IConditionItem {
  name: string;
  checked?: boolean;
}
export interface IIfOrConditionItem {
  key: string;
  shouldInclude: boolean | undefined;
}

// Use this function to do custom sorting for display of conditions of any category.
export const sortItems = <T extends IConditionItem>(
  sectionName: string,
  items: T[]
): T[] => {
  if (sectionName === "categories") {
    const othersIndex = items.findIndex((item) => item?.name === "その他");
    if (!othersIndex || othersIndex === -1) {
      return items;
    }

    const itemsCopy = [...items];
    const others = itemsCopy.splice(othersIndex, 1)[0];
    itemsCopy.push(others);

    return itemsCopy;
  }

  return items;
};

export const ModalFilterCondition: React.FunctionComponent<
  IModalFilterConditionProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`facilityListPage.modalFilterCondition.${key}`);

  const {
    organizationId,
    userId,
    hideLodging,
    hideHealthIndex,
    hideProfBools,
    onClose,
    onApply,
  } = props;

  const [conditionFormData, setConditionFormData] =
    useState<FacilityFilterConditionData>(createDefaultConditionFormData());

  const [selectAll, setSelectAll] = useState<any>({
    healthIndex: false,
    municipalities: [false, false],
    categories: false,
    labels: false,
  });

  const [hasLodging, setHasLodging] = useState<ProfBooleanData>({
    key: "has_lodging",
    shouldInclude: props.conditionFormData.hasLodging?.shouldInclude,
  });

  const health = _.cloneDeep(HEALTH_INDEX_OPTIONS);
  health.forEach((item) => {
    item.name = t(`healthIndexLabels.${item.key}`);
  });
  _.intersectionBy(health, props.conditionFormData.healthIndex, "name").forEach(
    (item) => (item.checked = true)
  );
  const profBools = _.cloneDeep(PROFILE_BOOLEAN_OPTIONS);
  props.conditionFormData.profBooleans.forEach((conditionFormItem) => {
    const itemIndex = profBools.findIndex(
      (boolItem) => boolItem.key === conditionFormItem.key
    );
    profBools[itemIndex].shouldInclude = conditionFormItem.shouldInclude;
  });

  const [healthIndexData, setHealthIndexData] =
    useState<HealthIndexData[]>(health);
  const [profBoolData, setProfBoolData] =
    useState<ProfBooleanData[]>(profBools);
  const [municipalitiesData, setMunicipalitiesData] = useState<
    MunicipalityGroupData[]
  >([]);
  const [categoriesData, setCategoriesData] = useState<CategoryData[]>([]);
  const [labelsData, setLabelsData] = useState<LabelData[]>([]);

  const [shownModalConfirmReset, setShownModalConfirmReset] =
    useState<boolean>(false);
  const [shownModalConfirmSelectAll, setShownModalConfirmSelectAll] =
    useState<boolean>(false);
  const { data: labels } = useLabels(
    _.pickBy({
      organization_id: organizationId,
    })
  );
  const { data: categories } = useCategories();
  const { data: municipalities } = useLocationMunis(
    userId,
    organizationId ?? ""
  );

  useEffect(() => {
    if (municipalities) {
      if (props.conditionFormData.municipalities) {
        const selectedMunicipalities =
          props.conditionFormData.municipalities.reduce<MunicipalityData[]>(
            (result, item) => {
              return result.concat(item.muniList);
            },
            []
          );
        _.intersectionBy(municipalities, selectedMunicipalities, "id").forEach(
          (item) => (item.checked = true)
        );
      }

      const prefArray = municipalities.filter((item) => {
        return item.type === "pref";
      });

      const selectAllTemp = selectAll;
      selectAllTemp.municipalities = [];
      const municipalitiesArray: MunicipalityGroupData[] = [];

      prefArray.forEach((itemPref) => {
        const muniArray = municipalities.filter((itemMuni) => {
          return (
            itemMuni.type === "muni" &&
            itemMuni.parent_external_id === itemPref.external_id
          );
        });

        municipalitiesArray.push({
          name: itemPref.name,
          muniList: muniArray,
        });
        selectAllTemp.municipalities.push(false);
      });

      setMunicipalitiesData(_.cloneDeep(municipalitiesArray));
      setSelectAll(_.cloneDeep(selectAllTemp));
    }
    // eslint-disable-next-line
  }, [municipalities]);

  useEffect(() => {
    if (categories) {
      if (props.conditionFormData.categories) {
        _.intersectionBy(
          categories,
          props.conditionFormData.categories,
          "id"
        ).forEach((item) => (item.checked = true));
      }
      const sortedCategories = sortItems("categories", categories);
      setCategoriesData(sortedCategories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  useEffect(() => {
    if (labels) {
      if (props.conditionFormData.labels) {
        _.intersectionBy(labels, props.conditionFormData.labels, "id").forEach(
          (item) => (item.checked = true)
        );
      }
      setLabelsData(labels);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels]);

  // Send GA event on open
  useEffect(() => {
    modalViewGAEvent("facility-list-filter");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Change lodging
  const changeLodging = (key: string, nextValue: boolean | undefined) => {
    setHasLodging({
      key,
      shouldInclude: nextValue,
    });
  };

  // change Checkbox
  const changeCheckbox = (value: boolean, index: number, name: string) => {
    let checkboxLabel = "";
    switch (name) {
      case "healthIndex":
        const healthIndexDataTemp = healthIndexData;
        healthIndexDataTemp[index].checked = value;
        checkboxLabel = healthIndexDataTemp[index].name;
        setHealthIndexData(_.cloneDeep(healthIndexDataTemp));
        break;
      case "categories":
        const categoriesDataTemp = categoriesData;
        categoriesDataTemp[index].checked = value;
        checkboxLabel = categoriesDataTemp[index].name;
        setCategoriesData(_.cloneDeep(categoriesDataTemp));
        break;
      case "labels":
        const labelsDataTemp = labelsData;
        labelsDataTemp[index].checked = value;
        checkboxLabel = labelsDataTemp[index].name;
        setLabelsData(_.cloneDeep(labelsDataTemp));
        break;
    }

    ReactGA.event({
      category: "Facility Filter",
      action: `Toggle ${name}`,
      label: checkboxLabel,
      value: value ? 0 : 1, // value === true is removing checkbox
    });
  };

  // change Prof Boolean

  const changeCheckboxProfBoolean = (
    key: string,
    nextValue: boolean | undefined
  ) => {
    const profBoolDataTemp = profBoolData;
    const index = profBoolData.findIndex((item) => item.key === key);
    profBoolDataTemp[index].shouldInclude = nextValue;

    setProfBoolData(_.cloneDeep(profBoolDataTemp));

    let gaValue;
    if (nextValue === true) {
      gaValue = 1;
    }
    if (nextValue === undefined) {
      gaValue = 0;
    }
    if (nextValue === false) {
      gaValue = -1;
    }

    ReactGA.event({
      category: "Facility Filter",
      action: `Toggle prof_bool`,
      label: key,
      value: gaValue,
    });
  };

  // change Checkbox Municipality
  const changeCheckboxMunicipality = (
    value: boolean,
    index: number,
    indexMunicipalityGroup: number
  ) => {
    const municipalitiesDataTemp = municipalitiesData;
    municipalitiesDataTemp[indexMunicipalityGroup].muniList[index].checked =
      value;
    setMunicipalitiesData(_.cloneDeep(municipalitiesDataTemp));

    ReactGA.event({
      category: "Facility Filter",
      action: `Toggle municipality`,
      label:
        municipalitiesDataTemp[indexMunicipalityGroup].muniList[index].name,
      value: value ? 0 : 1, // value === true is removing checkbox
    });
  };

  // change Select All
  const changeSelectAll = (value: boolean, name: string) => {
    const selectAllTemp = selectAll;

    switch (name) {
      case "healthIndex":
        selectAllTemp.healthIndex = value;
        const healthIndexDataTemp = healthIndexData;
        healthIndexDataTemp.forEach((item) => {
          item.checked = value;
        });
        setHealthIndexData(_.cloneDeep(healthIndexDataTemp));
        break;
      case "categories":
        selectAllTemp.categories = value;
        const categoriesDataTemp = categoriesData;
        categoriesDataTemp.forEach((item) => {
          item.checked = value;
        });
        setCategoriesData(_.cloneDeep(categoriesDataTemp));
        break;
      case "labels":
        selectAllTemp.labels = value;
        const labelsDataTemp = labelsData;
        labelsDataTemp.forEach((item) => {
          item.checked = value;
        });
        setLabelsData(_.cloneDeep(labelsDataTemp));
        break;
    }

    setSelectAll(_.cloneDeep(selectAllTemp));

    ReactGA.event({
      category: "Facility Filter",
      action: `Toggle ${name}`,
      label: "all",
      value: value ? 0 : 1, // value === true is removing checkbox
    });
  };

  // change Select All Municipality
  const changeSelectAllMunicipality = (
    value: boolean,
    indexMunicipalityGroup: number
  ) => {
    const selectAllTemp = selectAll;

    selectAllTemp.municipalities[indexMunicipalityGroup] = value;
    const municipalitiesDataTemp = municipalitiesData;
    municipalitiesDataTemp[indexMunicipalityGroup].muniList.forEach(
      (item: MunicipalityData) => {
        item.checked = value;
      }
    );
    setMunicipalitiesData(_.cloneDeep(municipalitiesDataTemp));

    setSelectAll(_.cloneDeep(selectAllTemp));

    ReactGA.event({
      category: "Facility Filter",
      action: `Toggle municipality`,
      label: "all",
      value: value ? 0 : 1, // value === true is removing checkbox
    });
  };

  const onClear = () => {
    changeLodging("has_lodging", undefined);
    changeSelectAll(false, "healthIndex");
    municipalitiesData.forEach((item, index) => {
      changeSelectAllMunicipality(false, index);
    });
    setProfBoolData(_.cloneDeep(PROFILE_BOOLEAN_OPTIONS));
    changeSelectAll(false, "categories");
    changeSelectAll(false, "labels");

    ReactGA.event({
      category: "Facility Filter",
      action: `Clear all`,
    });
  };

  const onSelectAll = () => {
    onClear();
    changeSelectAll(true, "healthIndex");
    // TODO: We want to apply and close the modal after selecting all
    // But because of all the useEffects, the modal will close itself before the changes are processed
    // Need to either rewrite this component, or navigate the logic to find an alternative
    // onClickApply();
  };

  const onClickApply = () => {
    const conditionFormDataTemp = conditionFormData;

    if (hasLodging.shouldInclude !== undefined) {
      conditionFormDataTemp.hasLodging = hasLodging;
    }

    conditionFormDataTemp.healthIndex = healthIndexData.filter(function (
      item: HealthIndexData
    ) {
      return item.checked;
    });

    const municipalitiesArray: MunicipalityGroupData[] = [];
    municipalitiesData.forEach((item) => {
      item.muniList = item.muniList.filter(function (item: MunicipalityData) {
        return item.checked;
      });

      municipalitiesArray.push(item);
    });

    conditionFormDataTemp.municipalities = municipalitiesArray;

    conditionFormDataTemp.categories = categoriesData.filter(function (
      item: CategoryData
    ) {
      return item.checked;
    });
    conditionFormDataTemp.labels = labelsData.filter(function (
      item: LabelData
    ) {
      return item.checked;
    });

    conditionFormData.profBooleans = profBoolData.filter(
      (item) => item.shouldInclude !== undefined
    );

    setConditionFormData(_.cloneDeep(conditionFormDataTemp));

    onApply(_.cloneDeep(conditionFormDataTemp));

    ReactGA.event({
      category: "Facility Filter",
      action: `Apply conditions`,
    });
  };

  return (
    <div className="filter-panel">
      <div className="filter-mains">
        <button
          className="icons btn-close"
          onClick={() => {
            onClose();
          }}
        ></button>
        <div className="top-title flex-grid">
          <div className="titles">{t("filter_condition")}</div>
          <div className="rights">
            <button
              className="btn btn-border"
              onClick={(event) => {
                setShownModalConfirmSelectAll(true);
                event.preventDefault();
              }}
            >
              {t("filter_select_all")}
            </button>
            <button
              className="btn btn-border"
              onClick={(event) => {
                setShownModalConfirmReset(true);
                event.preventDefault();
              }}
            >
              {t("clear")}
            </button>
          </div>
        </div>
        <div className="check-full-wrap">
          {!hideLodging && (
            <div className="check-groups">
              <div className="blue-block-row flex">
                <div className="blue-block">{t("fac_type")}</div>
              </div>
              <div className="check-groups-horizontal">
                <IfOrCondition
                  isBlueTitle={false}
                  item={hasLodging}
                  changeCheckbox={changeLodging}
                />
              </div>
            </div>
          )}
          {!hideHealthIndex && (
            <ItemCondition
              title={t("health_index")}
              checkedAll={selectAll.healthIndex}
              sectionName={"healthIndex"}
              isBlueTitle={true}
              dataList={healthIndexData}
              changeSelectAll={(checked: boolean, sectionName: string) => {
                changeSelectAll(checked, sectionName);
              }}
              changeCheckbox={(
                checked: boolean,
                index: number,
                sectionName: string
              ) => {
                changeCheckbox(checked, index, sectionName);
              }}
            />
          )}
          {!hideProfBools && (
            <div className="check-groups">
              <div className="blue-block-row flex">
                <div className="blue-block">{t("status")}</div>
              </div>
              <div className="check-groups-horizontal">
                {profBoolData.map((item) => (
                  <IfOrCondition
                    key={item.key}
                    isBlueTitle={false}
                    item={item}
                    changeCheckbox={changeCheckboxProfBoolean}
                  />
                ))}
              </div>
            </div>
          )}

          <div className="check-groups">
            <div className="blue-block-row flex">
              <div className="blue-block">{t("municipalities")}</div>
            </div>
            {municipalitiesData.map(
              (item: MunicipalityGroupData, indexMunicipalityGroup: number) => (
                <React.Fragment key={indexMunicipalityGroup}>
                  <ItemCondition
                    title={item.name}
                    checkedAll={
                      selectAll.municipalities[indexMunicipalityGroup]
                    }
                    sectionName={item.name}
                    isBlueTitle={false}
                    dataList={item.muniList}
                    changeSelectAll={(checked: boolean) => {
                      changeSelectAllMunicipality(
                        checked,
                        indexMunicipalityGroup
                      );
                    }}
                    changeCheckbox={(checked: boolean, index: number) => {
                      changeCheckboxMunicipality(
                        checked,
                        index,
                        indexMunicipalityGroup
                      );
                    }}
                  />
                </React.Fragment>
              )
            )}
          </div>
          <ItemCondition
            title={t("categories")}
            checkedAll={selectAll.categories}
            sectionName={"categories"}
            isBlueTitle={true}
            dataList={categoriesData}
            changeSelectAll={(checked: boolean, sectionName: string) => {
              changeSelectAll(checked, sectionName);
            }}
            changeCheckbox={(
              checked: boolean,
              index: number,
              sectionName: string
            ) => {
              changeCheckbox(checked, index, sectionName);
            }}
          />
          <ItemCondition
            title={t("labels")}
            checkedAll={selectAll.labels}
            sectionName={"labels"}
            isBlueTitle={true}
            dataList={labelsData}
            changeSelectAll={(checked: boolean, sectionName: string) => {
              changeSelectAll(checked, sectionName);
            }}
            changeCheckbox={(
              checked: boolean,
              index: number,
              sectionName: string
            ) => {
              changeCheckbox(checked, index, sectionName);
            }}
          />
        </div>
        <div className="bottom-btns">
          <button
            className="btn btn-border"
            onClick={() => {
              onClose();
            }}
          >
            {t("cancel")}
          </button>
          <button
            className="btn btn-blue"
            onClick={(event) => {
              onClickApply();
              event.preventDefault();
            }}
          >
            {t("apply")}
          </button>
        </div>
      </div>

      {shownModalConfirmReset && (
        <ModalConfirm
          title={"please_confirm_to_reset"}
          cancelLabel={"no-go"}
          confirmLabel={"go"}
          onClose={() => {
            setShownModalConfirmReset(false);
          }}
          onConfirm={() => {
            onClear();
            setShownModalConfirmReset(false);
          }}
        />
      )}
      {shownModalConfirmSelectAll && (
        <ModalConfirm
          title={"please_confirm_to_select_all"}
          cancelLabel={"no-go"}
          confirmLabel={"go"}
          onClose={() => {
            setShownModalConfirmSelectAll(false);
          }}
          onConfirm={() => {
            onSelectAll();
            setShownModalConfirmSelectAll(false);
          }}
        />
      )}
    </div>
  );
};

export default ModalFilterCondition;
