import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import { modalViewGAEvent } from "../../utils/ga";
import "./styles.scss";

export interface IModalConfirmProps {
  title: string;
  description?: string;
  secondaryDescription?: string;
  cancelLabel: string;
  confirmLabel: string;
  onClose: () => void;
  onConfirm: () => void;
  children?: React.ReactNode;
}

export const ModalConfirm: React.FunctionComponent<IModalConfirmProps> = (
  props
) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`modalConfirm.${key}`);

  // Send GA event on open
  useEffect(() => {
    modalViewGAEvent("confirm-modal");
  }, []);

  const {
    title,
    description,
    secondaryDescription,
    cancelLabel,
    confirmLabel,
    onClose,
    onConfirm,
    children,
  } = props;

  return (
    <div className="modal-default modal-confirm">
      <div className="modal-mains">
        <button
          className="btn-close"
          onClick={() => {
            ReactGA.event({
              category: "Confirm modal",
              action: "Cancel",
            });
            onClose();
          }}
        ></button>
        <div className="confirm-content-container">
          <div className="top-title">{t(title)}</div>

          {(description || secondaryDescription) && (
            <div className="description-txt">
              {description ? t(description) : null}
              {secondaryDescription ? secondaryDescription : null}
            </div>
          )}
          <div className="confirm-content">{children}</div>

          <div className="bottom-btns">
            {cancelLabel !== "" && (
              <button
                className="btn btn-border"
                onClick={() => {
                  ReactGA.event({
                    category: "Confirm modal",
                    action: "Cancel",
                  });
                  onClose();
                }}
              >
                {t(cancelLabel)}
              </button>
            )}

            <button
              className="btn btn-blue"
              onClick={() => {
                ReactGA.event({
                  category: "Confirm modal",
                  action: "Confirm",
                });
                onConfirm();
              }}
            >
              {t(confirmLabel)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirm;
