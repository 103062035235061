import i18n from "../../../i18n";
import { isUrlValid } from "../../../utils/formatValidation";
import { DateTimeInterval } from "../../FormElement/DateTimeIntervalInput/utils";

export interface CreateLocalPostState {
  locationIds?: number[];
  media: File[];
  mediaError?: string;
  text: string;
  messageTextError?: string;
  languageCode: string;
  callToAction: CallToAction;
  topicType: LocalPostTopicType;
  event: LocalPostEvent;
  offer: {
    couponCode: string;
    couponCodeError?: string;
    redeemOnlineUrl: string;
    redeemOnlineUrlError?: string;
    termsConditions: string;
    termsAndConditionsError?: string;
  };
  postToInstagram: boolean;
}

export type LocalPostEvent = {
  title: string;
  titleError?: string;
  schedule: DateTimeInterval;
  scheduleError: EventScheduleError;
};

export type EventScheduleError = {
  dateError?: string;
  timeError?: string;
};

export type CallToAction = {
  actionType: ActionType;
  url: string;
  urlError?: string;
};

export enum ActionType {
  ACTION_TYPE_UNSPECIFIED = "ACTION_TYPE_UNSPECIFIED",
  BOOK = "BOOK",
  ORDER = "ORDER",
  SHOP = "SHOP",
  LEARN_MORE = "LEARN_MORE",
  SIGN_UP = "SIGN_UP",
  CALL = "CALL",
}

export enum LocalPostTopicType {
  LOCAL_POST_TOPIC_TYPE_UNSPECIFIED = "LOCAL_POST_TOPIC_TYPE_UNSPECIFIED",
  STANDARD = "STANDARD",
  EVENT = "EVENT",
  OFFER = "OFFER",
  ALERT = "ALERT",
}

export enum CreateLocalPostActionTypes {
  CHANGE_TOPIC_TYPE = "CHANGE_TOPIC_TYPE",
  CHANGE_MEDIA = "CHANGE_MEDIA",
  CHANGE_MEDIA_ERROR = "CHANGE_MEDIA_ERROR",
  CHANGE_EVENT_TITLE = "CHANGE_EVENT_TITLE",
  CHANGE_EVENT_SCHEDULE = "CHANGE_EVENT_SCHEDULE",
  CHANGE_EVENT_SCHEDULE_ERROR = "CHANGE_EVENT_SCHEDULE_ERROR",
  CHANGE_BODY = "CHANGE_BODY",
  CHANGE_OFFER_COUPON_CODE = "CHANGE_OFFER_COUPON_CODE",
  CHANGE_OFFER_REDEEM_ONLINE_URL = "CHANGE_OFFER_REDEEM_ONLINE_URL",
  CHANGE_OFFER_TERMS_CONDITIONS = "CHANGE_OFFER_TERMS_CONDITIONS",
  CHANGE_CALL_TO_ACTION_TYPE = "CHANGE_CALL_TO_ACTION_TYPE",
  CHANGE_CALL_TO_ACTION_URL = "CHANGE_CALL_TO_ACTION_URL",
  CHANGE_POST_TO_INSTAGRAM = "CHANGE_POST_TO_INSTAGRAM",
}

export interface CreateLocalPostAction {
  type: CreateLocalPostActionTypes;
  payload: any;
}

export const t = (key: string) =>
  i18n.t(
    `analysisCollectionsPage.modalCreateLocalPost.createLocalPostForm.errors.${key}`
  );

export const createLocalPostStateReducer = (
  state: CreateLocalPostState,
  action: CreateLocalPostAction
): CreateLocalPostState => {
  switch (action.type) {
    case CreateLocalPostActionTypes.CHANGE_TOPIC_TYPE:
      return { ...state, topicType: action.payload };
    case CreateLocalPostActionTypes.CHANGE_MEDIA:
      return {
        ...state,
        media: action.payload,
        messageTextError: validateBody(state.text, action.payload),
        postToInstagram: state.postToInstagram && action.payload.length > 0,
      };
    case CreateLocalPostActionTypes.CHANGE_MEDIA_ERROR:
      return { ...state, mediaError: action.payload };
    case CreateLocalPostActionTypes.CHANGE_EVENT_TITLE:
      return {
        ...state,
        event: {
          ...state.event,
          title: action.payload,
          titleError:
            action.payload.length < 1
              ? t("event.title_too_short")
              : action.payload.length >= 58
              ? t("event.title_too_long")
              : undefined,
        },
      };
    case CreateLocalPostActionTypes.CHANGE_EVENT_SCHEDULE:
      const newSchedule = { ...action.payload };
      if (newSchedule.startTime === undefined) {
        delete newSchedule.startTime;
      }
      if (newSchedule.endTime === undefined) {
        delete newSchedule.endTime;
      }
      return {
        ...state,
        event: { ...state.event, schedule: newSchedule },
      };
    case CreateLocalPostActionTypes.CHANGE_EVENT_SCHEDULE_ERROR:
      return {
        ...state,
        event: { ...state.event, scheduleError: action.payload },
      };
    case CreateLocalPostActionTypes.CHANGE_BODY:
      return {
        ...state,
        text: action.payload,
        messageTextError: validateBody(action.payload, state.media),
      };
    case CreateLocalPostActionTypes.CHANGE_OFFER_COUPON_CODE:
      return {
        ...state,
        offer: {
          ...state.offer,
          couponCode: action.payload,
          couponCodeError:
            action.payload.length >= 58
              ? t("offer.coupon_code_too_long")
              : undefined,
        },
      };
    case CreateLocalPostActionTypes.CHANGE_OFFER_REDEEM_ONLINE_URL:
      let redeemOnlineUrlError = undefined;
      if (action.payload !== "" && !isUrlValid(action.payload)) {
        redeemOnlineUrlError = t("offer.url");
      }

      return {
        ...state,
        offer: {
          ...state.offer,
          redeemOnlineUrl: action.payload,
          redeemOnlineUrlError,
        },
      };
    case CreateLocalPostActionTypes.CHANGE_OFFER_TERMS_CONDITIONS:
      return {
        ...state,
        offer: {
          ...state.offer,
          termsConditions: action.payload,
          termsAndConditionsError:
            action.payload.length > 5000
              ? t("offer.terms_and_conditions_too_long")
              : undefined,
        },
      };
    case CreateLocalPostActionTypes.CHANGE_CALL_TO_ACTION_TYPE:
      return {
        ...state,
        callToAction: {
          ...state.callToAction,
          actionType: action.payload,
          urlError: validateCallToActionUrl({
            actionType: action.payload as ActionType,
            url: state.callToAction.url,
          }),
        },
      };
    case CreateLocalPostActionTypes.CHANGE_CALL_TO_ACTION_URL:
      return {
        ...state,
        callToAction: {
          ...state.callToAction,
          url: action.payload,
          urlError: validateCallToActionUrl({
            actionType: state.callToAction.actionType,
            url: action.payload,
          }),
        },
      };
    case CreateLocalPostActionTypes.CHANGE_POST_TO_INSTAGRAM:
      return { ...state, postToInstagram: action.payload };
    default:
      console.error(`Unhandled action type ${action.type}`);
      return state;
  }
};

const validateBody = (body: string, files: File[]): string | undefined => {
  let error: string | undefined = undefined;
  if (body.length > 1500) {
    error = t("message_too_long");
  }
  if (body.trim().length < 1 && files.length === 0) {
    error = t("message_too_short");
  }
  return error;
};

const validateCallToActionUrl = (cta: CallToAction): string | undefined => {
  if (
    cta.actionType === ActionType.ACTION_TYPE_UNSPECIFIED ||
    cta.actionType === ActionType.CALL
  ) {
    return undefined;
  }

  if (!isUrlValid(cta.url)) {
    return t("url");
  }

  return undefined;
};

export const isCreateLocalPostStateValid = (
  state: CreateLocalPostState
): boolean => {
  // Return false if the topic type is not supported
  if (
    state.topicType === LocalPostTopicType.LOCAL_POST_TOPIC_TYPE_UNSPECIFIED ||
    state.topicType === LocalPostTopicType.ALERT
  ) {
    return false;
  }

  // All Offer posts are built on Event posts, which are built on Standard posts,
  // So we can check each section and then return false early when invalid,
  // and return true once we reach the topic type.

  // The only caveat is that offer posts do not have a time range, so we need to exclude time range validation for offer posts.

  const isStandardSectionValid = isLocalPostStandardSectionValid(state);
  if (!isStandardSectionValid) {
    return false;
  }
  if (state.topicType === LocalPostTopicType.STANDARD) {
    return true;
  }

  const isEventSectionValid = isLocalPostEventSectionValid(state);
  if (!isEventSectionValid) {
    return false;
  }
  if (state.topicType === LocalPostTopicType.EVENT) {
    return true;
  }

  const isOfferSectionValid = isLocalPostOfferSectionValid(state);
  if (!isOfferSectionValid) {
    return false;
  }
  return true;
};

const isLocalPostStandardSectionValid = (
  state: CreateLocalPostState
): boolean => {
  // Return false if there is a message text error
  if (state.messageTextError) return false;
  // Return false if there is no text or media
  if (state.text === "" && state.media.length === 0) return false;
  // Return false if there is a url error and the action type is not "ACTION_TYPE_UNSPECIFIED" or "CALL"
  if (
    state.callToAction.urlError &&
    state.callToAction.actionType !== ActionType.ACTION_TYPE_UNSPECIFIED &&
    state.callToAction.actionType !== ActionType.CALL
  ) {
    return false;
  }
  // Return true if the code reaches this point
  return true;
};

const isLocalPostEventSectionValid = (state: CreateLocalPostState): boolean => {
  // Return false if there no title
  if (state.event.title === "") {
    return false;
  }
  // Return false if there is a title error
  if (state.event.titleError) {
    return false;
  }
  // Return false if there is no start date or end date
  if (
    state.event.schedule.startDate === undefined ||
    state.event.schedule.endDate === undefined
  ) {
    return false;
  }
  // Return false if there is a date error
  if (state.event.scheduleError.dateError) {
    return false;
  }
  // Return false if there is a time error and the topic type is "EVENT"
  if (
    state.event.scheduleError.timeError &&
    state.topicType === LocalPostTopicType.EVENT
  ) {
    return false;
  }
  // Return true if the code reaches this point
  return true;
};

const isLocalPostOfferSectionValid = (state: CreateLocalPostState): boolean => {
  // Return false if there is a coupon code error
  if (state.offer.couponCodeError) {
    return false;
  }
  // Return false if there is a redeem online url error
  if (state.offer.redeemOnlineUrlError) {
    return false;
  }
  // Return false if there is a terms and conditions error
  if (state.offer.termsAndConditionsError) {
    return false;
  }
  // Return true if the code reaches this point
  return true;
};
