import dayjs from "dayjs";
import React, { useState, useRef } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { ReviewData } from "../../../models/ReviewData";
import dataSvc from "../../../services/dataSvc";
import ModalConfirm from "../../ModalConfirm";

import "./styles.scss";

export interface IModalUnRepliedReviewsProps {
  review: ReviewData;
  onDelete: (review_id: string) => void;
}

export const UnRepliedReviewItem: React.FunctionComponent<
  IModalUnRepliedReviewsProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`facilityDetailsPage.unRepliedReviewItem.${key}`);
  const { review, onDelete } = props;

  const [isSent, setIsSent] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [repliedContent, setRepliedContent] = useState<string>("");
  const [shownModalConfirm, setShownModalConfirm] = useState<boolean>(false);
  const [aiReplyUsed, setAiReplyUsed] = useState<boolean>(false);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const onOpenModalConfirm = () => {
    if (repliedContent === "") {
      alert(t("please_enter_reply"));
      return;
    }
    setShownModalConfirm(true);
  };

  const onHandleSend = async () => {
    setIsSent(true);
    setShownModalConfirm(false);

    ReactGA.event({
      category: "facility_details",
      action: "Reply Review",
      label: aiReplyUsed ? "AI used" : "Manual reply",
    });

    try {
      await dataSvc.postReplyReview({
        review_id: review.review_id,
        locationId: review.location_id,
        reply: repliedContent,
      });

      toast.success(t("reply_successfully"));
      setTimeout(() => {
        setIsCollapsed(true);
      }, 1000);
    } catch {
      // Errors are handled in dataSvc
    }

    setTimeout(() => {
      onDelete(review.review_id);
    }, 300);
  };

  const handleUseAiReply = () => {
    if (textAreaRef.current != null && review.processed_review?.gemini_reply) {
      textAreaRef.current.value = review.processed_review.gemini_reply;
      setRepliedContent(review.processed_review.gemini_reply);

      ReactGA.event({
        category: "facility_details",
        action: "View AI generated reply",
      });

      setAiReplyUsed(true);
    }
  };

  return (
    <div className={`main-container ${isCollapsed ? "collapse" : ""}`}>
      <div>
        {review?.reviewer_name && (
          <div className="reviewer-name">{`${t("reviewer_name")} ${
            review.reviewer_name
          }`}</div>
        )}
      </div>
      <div className="top-container">
        <div className="rating">
          {Array.from({ length: review.rating ?? 0 }, (_, i) => (
            <span key={i}>⭐️</span>
          ))}
        </div>
        <div className="review-date">
          {dayjs(review.review_date).format("YYYY-MM-DD")}
        </div>
      </div>
      <div className="comment">
        {review.processed_review?.comment ?? review.comment}
      </div>
      {review.processed_review?.translation && (
        <div className="translation-title">
          <div className="generate-by-ai">{`${t("title_ai_generate")}`}</div>
          <div className="translation">{`${review.processed_review.translation}`}</div>
        </div>
      )}
      <textarea
        ref={textAreaRef}
        onChange={(e) => setRepliedContent(e.target.value)}
        placeholder={t("placeHolder")}
        className="reply-container"
      />
      <div className="bottom-container">
        {review.processed_review?.gemini_reply && (
          <button onClick={handleUseAiReply} className="ai-reply">{`${t(
            "button_ai_reply"
          )}`}</button>
        )}
        <button onClick={onOpenModalConfirm} className="sent" disabled={isSent}>
          {isSent ? t("replied") : t("sent")}
        </button>
      </div>

      {shownModalConfirm && (
        <ModalConfirm
          title={"are_you_sure_want_to_send"}
          cancelLabel={"cancel"}
          confirmLabel={"confirm"}
          onClose={() => {
            setShownModalConfirm(false);
          }}
          onConfirm={onHandleSend}
        />
      )}
    </div>
  );
};

export default UnRepliedReviewItem;
